import request from '@/utils/request'

const PREFIX = '/api/supervisor/degreeday'

export function fetchDegreedayInYear (year) {
  return request({
    url: PREFIX,
    method: 'get',
    params: { year }
  })
}

export function fetchDegreedayInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: PREFIX,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function saveDegreeday (rows) {
  return request({
    url: `${PREFIX}`,
    method: 'post',
    data: rows
  })
}
