import request from '@/utils/request'

const PREFIX = '/api/core/system-feeding-plans'

export function fetchSystemFeedingPlans () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function createSystemFeedingPlan (data) {
  return request({
    url: PREFIX,
    method: 'post',
    data
  })
}

export function updateSystemFeedingPlanById (id, data) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    data
  })
}

export function deleteSystemFeedingPlanById (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}

export function copySystemFeedingPlanById (id) {
  return request({
    url: `${PREFIX}/${id}/copy-to-local`,
    method: 'get'
  })
}
