import store from 'store'
import defaultsPlugin from 'store/plugins/defaults'

export const KEYS = {
  FEED_RATION_COLUMNS_SELECTION: 'feed-ration-columns-selection',
  TANKS_MAP_SCALE: 'tanks-map-scale',
  IS_EMPTY_SITES_VISIBLE: 'is-empty-sites-visible'
}

store.addPlugin(defaultsPlugin)

store.defaults({
  [KEYS.TANKS_MAP_SCALE]: 1,
  [KEYS.IS_EMPTY_SITES_VISIBLE]: true
})

export const ls = store

export default store
