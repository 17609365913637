import request from '@/utils/request'

const PREFIX = '/api/supervisor/density'

export function fetchDensityInYear (year) {
  return request({
    url: PREFIX,
    method: 'get',
    params: { year }
  })
}

export function fetchDensityInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: PREFIX,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function saveDensity (rows) {
  return request({
    url: `${PREFIX}`,
    method: 'post',
    data: rows
  })
}
