import request from '@/utils/request'

const PREFIX = '/api/supervisor/production'

export function fetchFactoryContentInPeriod (start, end) {
  return request({
    url: `${PREFIX}/factory-content`,
    method: 'get',
    params: { start, end }
  })
}

export function fetchPlanMetricsInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/metrics/plan`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchEstimationMetricsInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/metrics/estimation`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchFactMetricsInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/metrics/fact`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}
