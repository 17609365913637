<template>
  <div class="app-navbar" @mouseenter="onEnter">
    <div class="navbar-items">
      <router-link
        v-for="item in items"
        :key="item.path"
        :to="item.path"
        class="navbar-item"
      >
        <i :class="['navbar-item__icon', 'mdi', `mdi-${item.icon}`]" />
        <span class="navbar-item__label">{{ item.label }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  computed: {
    items () {
      return this.$store.state.core.app.navbarItems
    }
  },
  methods: {
    onEnter (e) {
      e.target.style.zIndex = PopupManager.nextZIndex()
    }
  }
}
</script>

<style lang="scss">
.app-navbar {
  --item-background-color: #2C2C2D;
  --item-text-color: #e6e6e6;
  position: relative;
  width: var(--navbar-width);
  transition: width 0.3s, opacity 0.3s;
  &:hover {
    width: 220px;
    opacity: 0.8;
  }
  background-color: var(--item-background-color);
  color: var(--item-text-color);
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;
  .navbar-items {
    margin: 4px 0 4px;
    .navbar-item {
      box-sizing: border-box;;
      padding: 0.25rem 0.75rem;
      margin: 1px 4px;
      border-radius: 4px;
      width: auto;
      display: flex;
      align-items: center;
      text-decoration: none;
      white-space: nowrap;
      background-color: var(--item-background-color);
      color: var(--item-text-color);
      &:hover {
        background-color: hsl(0, 0%, 42%);
      }
      &__icon {
        font-size: 1.5rem;
        margin-right: 0.5rem;
      }
      &__label {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.router-link-active {
        background-color: hsl(0, 0%, 32%);
        .navbar-item__icon,
        .navbar-item__label {
          color: var(--color-primary-500);
        }
      }
    }
  }
}
</style>
