<template>
  <page-splash
    :header="$t('core.label')"
    :label="label"
    :message="message"
    :animated="true"
    :cover-toolbar="true"
    :cover-navbar="true"
  />
</template>

<script>
import PageSplash from '@/modules/core/components/PageSplash'

export default {
  components: {
    PageSplash
  },
  computed: {
    label () {
      return this.isUpdating
        ? this.$t('core.statuses.updating')
        : this.$t('core.statuses.loading')
    },
    isUpdating () {
      return this.$store.state.core.app.isUpdating
    },
    message () {
      if (this.isUpdating) {
        return this.$store.state.core.app.updatingMessage
      }
      const action = this.$store.state.core.app.loadings[0]
      return action ? this.$t(`core.loading.${action}`) : ''
    }
  }
}
</script>
