import request from '@/utils/request'

const PREFIX = '/api/core/preferences'

export function fetchPreferences () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function savePreferences (preferences) {
  return request({
    url: PREFIX,
    method: 'put',
    data: preferences
  })
}
