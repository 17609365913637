<template>
  <div class="help-editor">
    <h3 class="help-editor-section">
      {{ $t('core.help.links') }}
    </h3>
    <el-form size="small" label-position="top">
      <el-form-item :label="$t('core.help.techSupportEmail')">
        <el-input v-model="form.supportEmail" type="email" />
      </el-form-item>

      <el-form-item :label="$t('core.help.userManual')">
        <el-upload
          ref="userManualUpload"
          action=""
          accept="application/pdf"
          :auto-upload="false"
          :multiple="false"
          :show-file-list="true"
          :on-change="onChange('userManual')"
          :on-remove="onRemove('userManual')"
        >
          <el-button slot="trigger" size="mini">
            {{ $t('core.misc.selectFile') }}
          </el-button>
        </el-upload>
      </el-form-item>

      <el-form-item :label="$t('core.help.userAgreement')">
        <el-upload
          ref="userAgreementUpload"
          action=""
          accept="application/pdf"
          :auto-upload="false"
          :multiple="false"
          :show-file-list="true"
          :on-change="onChange('userAgreement')"
          :on-remove="onRemove('userAgreement')"
        >
          <el-button slot="trigger" size="mini">
            {{ $t('core.misc.selectFile') }}
          </el-button>
        </el-upload>
      </el-form-item>

      <el-form-item :label="$t('core.help.personalDataProcessingConsent')">
        <el-upload
          ref="personalDataProcessingConsentUpload"
          action=""
          accept="application/pdf"
          :auto-upload="false"
          :multiple="false"
          :show-file-list="true"
          :on-change="onChange('personalDataProcessingConsent')"
          :on-remove="onRemove('personalDataProcessingConsent')"
        >
          <el-button slot="trigger" size="mini">
            {{ $t('core.misc.selectFile') }}
          </el-button>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button
          size="small"
          :loading="loading"
          type="primary"
          @click="handleSubmit"
        >
          {{ $t('core.misc.save') }}
        </el-button>
        <el-button
          size="small"
          @click="handleCancel"
        >
          {{ $t('core.misc.cancel') }}
        </el-button>
      </el-form-item>
    </el-form>

    <h3 class="help-editor-section">
      {{ $t('core.help.import/export help') }}
    </h3>
    <el-form size="small" label-position="top">
      <el-form-item>
        <el-upload
          ref="helpDumpUpload"
          action=""
          accept="application/zip"
          :auto-upload="false"
          :multiple="false"
          :show-file-list="true"
          :on-change="onChange('helpDump')"
          :on-remove="onRemove('helpDump')"
        >
          <el-button slot="trigger" size="small">
            {{ $t('core.misc.selectFile') }}
          </el-button>

          <el-button
            size="small"
            :loading="loading"
            style="margin-left: 1em;"
            @click="handleImport"
          >
            {{ $t('core.help.importHelp') }}
          </el-button>
        </el-upload>
      </el-form-item>

      <el-form-item>
        <el-button
          size="small"
          :loading="loading"
          @click="handleExport"
        >
          {{ $t('core.help.exportHelp') }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { Upload } from 'element-ui'
import { importHelp, exportHelp } from '@/modules/core/api/help'
import { saveFileAs } from '@/modules/core/use/file'
import { parseError } from '@/utils/request'

export default {
  components: {
    ElUpload: Upload
  },
  data () {
    const form = {
      supportEmail: '',
      userManual: null,
      userAgreement: null,
      personalDataProcessingConsent: null,
      helpDump: null
    }
    return {
      loading: false,
      form,
      formKey: this.getFormKey(form)
    }
  },
  computed: {
    curFormKey () {
      return this.getFormKey(this.form)
    }
  },
  watch: {
    curFormKey (curFormKey) {
      this.$store.commit('help/SET_IS_BUSY', curFormKey !== this.formKey)
    }
  },
  created () {
    this.resetForm()
  },
  methods: {
    async handleSubmit () {
      try {
        await this.submitDocuments()
        await this.$store.dispatch('help/saveMessage', {
          name: 'SUPPORT_EMAIL',
          content: this.form.supportEmail
        })
        await this.$store.dispatch('help/fetchMessages')
        this.resetForm()
      } catch (e) {
        this.$notify.error({
          title: this.$t('core.help.saveLinksOperation'),
          message: parseError(e)
        })
      }
    },
    async submitDocuments () {
      const formData = new FormData()
      if (this.form.userManual) {
        formData.append('userManual', this.form.userManual)
      }
      if (this.form.userAgreement) {
        formData.append('userAgreement', this.form.userAgreement)
      }
      if (this.form.personalDataProcessingConsent) {
        formData.append(
          'personalDataProcessingConsent',
          this.form.personalDataProcessingConsent
        )
      }
      if (Array.from(formData.keys()).length > 0) {
        await this.$store.dispatch('help/saveDocuments', formData)
      }
    },
    async handleExport () {
      try {
        this.loading = true
        const blob = (await exportHelp()).data
        const exportedAt = new Date().toISOString().slice(0, 10)
        saveFileAs(blob, `fishweb.help.${exportedAt}.zip`)
      } catch (e) {
        this.$notify.error({
          title: this.$t('core.help.exportHelpOperation'),
          message: parseError(e)
        })
      } finally {
        this.loading = false
      }
    },
    async handleImport () {
      try {
        this.loading = true
        await this.importHelp()
        this.resetForm()
        await this.$store.dispatch('help/fetchMessages')
      } catch (e) {
        this.$notify.error({
          title: this.$t('core.help.importHelpOperation'),
          message: parseError(e)
        })
      } finally {
        this.loading = false
      }
    },
    async importHelp () {
      const formData = new FormData()
      if (this.form.helpDump) {
        formData.append('zip', this.form.helpDump)
      }
      if (Array.from(formData.keys()).length > 0) {
        await importHelp(formData)
      }
    },
    handleCancel () {
      this.resetForm()
    },
    resetForm () {
      const messages = this.$store.getters['help/messageByNames']
      this.form = {
        supportEmail: messages.SUPPORT_EMAIL || '',
        userManual: null,
        userAgreement: null,
        personalDataProcessingConsent: null,
        helpDump: null
      }
      this.formKey = this.getFormKey(this.form)
      this.$refs.userManualUpload?.clearFiles()
      this.$refs.userAgreementUpload?.clearFiles()
      this.$refs.personalDataProcessingConsentUpload?.clearFiles()
      this.$refs.helpDumpUpload?.clearFiles()
    },
    getFormKey (form) {
      return [
        form.supportEmail,
        form.userManual?.uid,
        form.userAgreement?.uid,
        form.personalDataProcessingConsent?.uid,
        form.helpDump?.uid
      ].join(',')
    },
    onChange (field) {
      return (file, fileList) => {
        fileList.splice(0, fileList.length, file)
        this.$set(this.form, field, file.raw)
      }
    },
    onRemove (field) {
      return (file, fileList) => {
        this.$set(this.form, field, null)
      }
    }
  }
}
</script>

<style lang="scss">
.help-editor {
  overflow: auto;
  padding: 1em;
  .help-editor-section {
    margin: 2em 0 1em;
  }
  .help-editor-section:first-of-type {
    margin-top: 0;
  }
}
</style>
