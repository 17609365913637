import request from '@/utils/request'

const PREFIX = '/api/core/feed-producers'

export function fetchFeedProducers () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function fetchFeedingSummaryInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/consumption`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function createFeedProducer (data) {
  return request({
    url: PREFIX,
    method: 'post',
    data
  })
}

export function updateFeedProducerById (id, data, params) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    params,
    data
  })
}

export function deleteFeedProducerById (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}
export function fetchFeedHandbooksMentionInFeedingPlans (id) {
  return request({
    url: `${PREFIX}/${id}/handbooks-mention/feeding-plans`,
    method: 'get'
  })
}
