import * as api from '@/modules/core/api/help'

export default {
  namespaced: true,
  state: {
    isBusy: false,
    messages: []
  },
  getters: {
    messageByNames: (state) => {
      return state.messages.reduce((acc, item) => {
        acc[item.name] = item.content
        return acc
      }, {})
    }
  },
  mutations: {
    SET_IS_BUSY: (state, isBusy) => {
      state.isBusy = isBusy
    },
    SET_MESSAGES: (state, messages) => {
      state.messages = messages
    }
  },
  actions: {
    async fetchMessages ({ commit }) {
      const response = await api.fetchHelpMessages()
      commit('SET_MESSAGES', response.data)
    },
    async saveMessage (_, payload) {
      await api.saveHelpMessage(payload)
    },
    async saveDocuments (_, formData) {
      await api.saveDocuments(formData)
    }
  }
}
