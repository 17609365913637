<template>
  <el-cascader
    :value="optionByTypes[value]"
    :options="options"
    :props="{
      expandTrigger: 'hover'
    }"
    :placeholder="$t('core.wizard.pickForm')"
    size="small"
    filterable
    data-test="type-cascader"
    class="wizard-type-picker"
    @change="updateValue"
  >
    <template #default="{ data }">
      <span :data-test="`node-${data.value}`">
        {{ data.label }}
      </span>
    </template>
  </el-cascader>
</template>

<script>
import { Cascader } from 'element-ui'
import { getWaterIndicators } from '@/modules/core/use/preferences'

export default {
  components: {
    ElCascader: Cascader
  },
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      optionByTypes: {
        fishWeight: ['fishGroup', 'fishWeight'],
        seeding: ['fishGroup', 'seeding'],
        mortality: ['fishGroup', 'mortality'],
        catch: ['fishGroup', 'catch'],
        moving: ['fishGroup', 'moving'],
        feeding: ['feeding'],
        batch: ['batch'],
        water: ['water'],
        resetTanks: ['resetTanks']
      }
    }
  },
  computed: {
    options () {
      return [
        {
          value: 'fishGroup',
          label: this.$t('core.indicator.groups.fish'),
          children: [
            { value: 'fishWeight', label: this.$t('core.metrics.fishWeight') },
            { value: 'seeding', label: this.$t('core.metrics.seeding') },
            { value: 'mortality', label: this.$t('core.metrics.mortality') },
            { value: 'catch', label: this.$t('core.metrics.catch') },
            { value: 'moving', label: this.$t('core.metrics.moving') }
          ]
        },
        {
          value: 'feeding',
          label: this.$t('core.indicator.groups.feeding')
        },
        {
          value: 'batch',
          label: this.$t('core.indicator.groups.batch')
        },
        ...this.waterIndicatorTypes.length
          ? [{
            value: 'water',
            label: this.$t('core.indicator.groups.waterIndicators')
          }]
          : [],
        {
          value: 'feedRation',
          label: this.$t('core.feedRation.ration')
        },
        {
          value: 'resetTanks',
          label: this.$t('core.wizard.types.resetTanks')
        }
      ]
    },
    waterIndicatorTypes () {
      return getWaterIndicators(this.$store.state.preferences.all)
    }
  },
  methods: {
    updateValue (value) {
      const type = value ? value[value.length - 1] : null
      if (this.value !== type) {
        this.$emit('input', type)
      }
    }
  }
}
</script>
