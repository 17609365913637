<template>
  <div class="app-toolbar">
    <toolbar-app-logo />
    <user-menu />
    <factory-menu v-if="isAllowedReadMultifactories" />
    <help-panel style="margin-left: auto; padding: 0 1em; align-self: center;" />
  </div>
</template>

<script>
import acl from '@/acl'
import UserMenu from '@/modules/core/components/ToolbarUserMenu'
import ToolbarAppLogo from '@/modules/core/components/ToolbarAppLogo'
import HelpPanel from '@/modules/core/components/HelpPanel'

export default {
  name: 'Toolbar',

  components: {
    ToolbarAppLogo,
    UserMenu,
    HelpPanel,
    FactoryMenu: () => import('@/modules/core/components/ToolbarFactoryMenu')
  },

  data () {
    return {
      isAllowedReadMultifactories: acl.isAllowed(acl.P.R, acl.R.Multifactories)
    }
  }
}
</script>

<style scoped lang="scss">
.app-toolbar {
  height: var(--toolbar-height);
  overflow: hidden;
  display: flex;
  box-sizing: border-box;

  .push {
    margin-left: auto;
  }
}
</style>
