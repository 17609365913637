<template>
  <div class="login-container">
    <el-card v-loading="loading" class="login-form">
      <el-form ref="loginForm" :model="loginForm" :rules="rules">
        <div class="title">
          {{ $t('core.label') }}
        </div>

        <el-form-item :label="$t('core.auth.form.login')" prop="username">
          <el-input
            v-model="loginForm.username"
            name="username"
            type="text"
            auto-complete="on"
            prefix-icon="mdi mdi-account"
          />
        </el-form-item>

        <el-form-item :label="$t('core.auth.form.password')" prop="password">
          <el-input
            v-model="loginForm.password"
            name="password"
            auto-complete="on"
            prefix-icon="mdi mdi-key"
            type="password"
            show-password
            @keyup.enter.native="handleLogin"
          />
        </el-form-item>

        <br>

        <el-button
          type="primary"
          style="width:100%;"
          :loading="loading"
          @click.native.prevent="handleLogin"
        >
          {{ $t('core.auth.login') }}
        </el-button>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import acl from '@/acl'
import loadingMix from '@/modules/core/mixins/loading'
import { parseError } from '@/utils/request'

export default {
  name: 'Login',
  mixins: [loadingMix],
  data () {
    const requiredRule = {
      required: true,
      message: this.$t('core.validation.requiredField')
    }

    return {
      loginForm: {
        username: '',
        password: ''
      },
      rules: {
        username: requiredRule,
        password: requiredRule
      }
    }
  },
  methods: {
    async handleLogin () {
      try {
        await this.$refs.loginForm.validate()
      } catch {
        return
      }
      try {
        this.turnLoading(true)
        await this.$store.dispatch('auth/loginByUsername', this.loginForm)
        this.$store.dispatch('core/factories/initFactories', {
          login: this.loginForm.username,
          password: this.loginForm.password
        })
        await this.$store.dispatch('core/app/bootstrap')
        this.$router.push(this.getNextRoute())
      } catch (e) {
        this.$notify.error({
          title: this.$t('core.auth.loginOperation'),
          message: parseError(e)
        })
      } finally {
        this.turnLoading(false)
      }
    },
    getNextRoute () {
      if (
        acl.isAllowed(acl.P.X, acl.R.Factory) &&
        this.$store.getters['factory/tanks/forUser'].length === 0
      ) {
        return { name: 'map.factory' }
      }
      return this.$route.query.redirect || '/'
    }
  }
}
</script>

<style lang="scss">
body {
  margin: 0 !important;
  padding: 0 !important;
}

.login-container {
  height: calc(100vh);
  width: 100%;
  box-sizing: border-box !important;
  background-color: hsl(0, 0%, 96%);
  border: 1px solid transparent;

  .login-form {

    .title {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }

    margin-top: 20vh;
    margin-left: auto;
    margin-right: auto;
    height: 340px;
    width: min(400px, 80%);
    padding: 0 0 25px 0;
  }
}
</style>
