import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep } from 'lodash'
import {
  startOfDay,
  sub as subToDate
} from '@/utils/date'
import * as api from '@/modules/iot/api/thermoOximeter'
import { getUnionTanksBySite } from '@/modules/core/use/factory'

const periodCurDay = [
  startOfDay(subToDate(new Date(), { days: 1 })).toISOString(),
  new Date().toISOString()
]
const defaultThermoOximeter = {
  id: null,
  __v: undefined,
  number: null,
  name: null,
  batteryLevel: null,
  powered: false,
  status: 'active',
  location: {
    lat: null,
    lng: null
  },
  tankIds: [],
  timeWorked: null,
  operatingMode: null,
  lastIndicatorDate: null,
  remainingTimeWork: null,
  nextDataRequest: null,
  workRules: [],
  history: [],
  indicators: {
    temperature: [],
    oxygen: []
  }
}

export default {
  namespaced: true,
  state: {
    all: [],
    current: null,
    periodFilter: {
      history: [...periodCurDay],
      indicators: [...periodCurDay]
    }
  },
  getters: {
    getField,

    names: state => {
      return state.all.reduce((names, thermooximeter) => {
        names[thermooximeter.id] = thermooximeter.name
        return names
      }, {})
    },
    bindingByIds: (state, getters, rootState, rootGetters) => {
      const siteNames = rootGetters['factory/sites/names']
      const tankLocations = rootGetters['factory/tanks/locations']

      return state.all.reduce((acc, thermooximeter) => {
        const unionTanksBySite = getUnionTanksBySite(
          thermooximeter.tankIds,
          rootState.factory.allContent
        )
        const binding = []
        for (const siteId of unionTanksBySite.sites) {
          binding.push({
            key: siteId,
            text: siteNames[siteId]
          })
        }
        for (const tankId of unionTanksBySite.tanks) {
          binding.push({
            key: tankId,
            text: tankLocations[tankId]
          })
        }
        acc[thermooximeter.id] = binding
        return acc
      }, {})
    },
    getPeriodFilterHistory: (state) => {
      return state.periodFilter.history
    },
    getPeriodFilterIndicators: (state) => {
      return state.periodFilter.indicators
    }
  },
  mutations: {
    updateField,
    SET_ALL: (state, data) => {
      state.all = data
    },
    SET_CURRENT: (state, data) => {
      Vue.set(state, 'current', {
        ...cloneDeep(defaultThermoOximeter),
        ...cloneDeep(data)
      })
    },
    UPDATE_CURRENT: (state, data) => {
      const current = state.current
      for (const [key, value] of Object.entries(data)) {
        Vue.set(current, key, value)
      }
      const index = state.all.findIndex(item => item.id === current.id)
      if (index !== -1) {
        state.all.splice(index, 1, cloneDeep(current))
      }
    },
    SET_PERIOD_FILTER_HISTORY: (state, data) => {
      Vue.set(state.periodFilter, 'history', data)
    },
    SET_PERIOD_FILTER_INDICATORS: (state, data) => {
      Vue.set(state.periodFilter, 'indicators', data)
    }
  },
  actions: {
    async fetchAll ({ commit }) {
      let response
      try {
        response = await api.fetchAll()
      } catch (error) {
        return Promise.reject(error)
      }
      commit('SET_ALL', response.data)
    },
    async get ({ commit }, { id }) {
      let response
      try {
        response = await api.get(id)
      } catch (error) {
        return Promise.reject(error)
      }
      commit('SET_CURRENT', response.data)
    },
    async update ({ commit }, { id, data }) {
      try {
        await api.update({ id, data })
        const { data: thermooximeter } = await api.get(id)
        commit('UPDATE_CURRENT', thermooximeter)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getHistoryInPeriod ({ commit }, { id, params }) {
      let response
      try {
        response = await api.getHistoryInPeriod({ id, params })
      } catch (error) {
        return Promise.reject(error)
      }
      commit('UPDATE_CURRENT', { history: response.data })
    },
    async getIndicatorsInPeriod ({ commit }, { id, params }) {
      let response
      try {
        response = await api.getIndicatorsInPeriod({ id, params })
      } catch (error) {
        return Promise.reject(error)
      }
      commit('UPDATE_CURRENT', { indicators: response.data })
    },
    async createWorkRule ({ commit }, { id, data }) {
      try {
        await api.createWorkRule({ id, data })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateWorkRule ({ commit }, { id, workRuleId, data }) {
      try {
        await api.updateWorkRule({ id, workRuleId, data })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getWorkRules ({ commit }, { id }) {
      let response
      try {
        response = await api.getWorkRules({ id })
      } catch (error) {
        return Promise.reject(error)
      }
      commit('UPDATE_CURRENT', { workRules: response.data })
    },
    async removeWorkRule ({ commit }, { id, workRuleId }) {
      try {
        await api.removeWorkRule({ id, workRuleId })
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
