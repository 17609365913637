import { throttle } from 'lodash'
import * as api from '@/modules/core/api/feedProducers'
import {
  makeNameForFeedProducer,
  feedProducersComparatorByName
} from '@/modules/core/use/feedProducer'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    all: [],
    isLoading: false,
    summary: []
  },
  getters: {
    names: state => {
      return state.all.reduce((names, producer) => {
        names[producer.id] = makeNameForFeedProducer(producer)
        return names
      }, {})
    },
    byIds: state => {
      return state.all.reduce((acc, feedProducer) => {
        acc[feedProducer.id] = feedProducer
        return acc
      }, {})
    },
    getById: (state, getters) => feedProducerId => {
      return getters.byIds[feedProducerId]
    },
    handbookByIds: state => {
      const handbooks = state.all.map(producer => producer.handbooks).flat()
      return handbooks.reduce((acc, handbook) => {
        acc[handbook.id] = handbook
        return acc
      }, {})
    },
    handbookNames: state => {
      const handbooks = state.all.map(producer => producer.handbooks).flat()
      return handbooks.reduce((acc, handbook) => {
        acc[handbook.id] = handbook.name
        return acc
      }, {})
    }
  },
  mutations: {
    SET_LOADING_STATUS: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_ALL: (state, feedProducers) => {
      const sortedFeedProducers = feedProducers.slice()
      sortedFeedProducers.sort(feedProducersComparatorByName)
      state.all = sortedFeedProducers
    },
    SET_FEEDING_SUMMARY: (state, summary) => {
      Vue.set(state, 'summary', summary)
    }
  },
  actions: {
    async fetchFeedingSummaryInPeriod ({ commit }, params) {
      try {
        const response = await api.fetchFeedingSummaryInPeriod(params)
        const feedingSummary = response.data
        commit('SET_FEEDING_SUMMARY', feedingSummary)
        return feedingSummary
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchFeedProducers ({ commit }) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.fetchFeedProducers()
        const feedProducers = response.data
        commit('SET_ALL', feedProducers)
        return feedProducers
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    throttledFetchFeedProducers: throttle(({ dispatch }) => {
      return dispatch('fetchFeedProducers')
    }, 10000, {
      leading: true,
      trailing: false
    }),
    async createFeedProducer ({ commit, dispatch }, feedProducer) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.createFeedProducer(feedProducer)
        const feedProducerId = response.data
        await dispatch('fetchFeedProducers')
        return feedProducerId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async updateFeedProducerById ({ commit, dispatch }, { id, data, options }) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.updateFeedProducerById(id, data, options)
        await dispatch('fetchFeedProducers')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async deleteFeedProducerById ({ state, commit, dispatch }, id) {
      try {
        commit('SET_LOADING_STATUS', true)
        const feed = state.all.find((feed) => feed.id === id)
        await api.deleteFeedProducerById(id)
        await dispatch('fetchFeedProducers')
        if (feed?.copyOf) {
          commit('core/systemFeedProducers/DELETE_COPY_OF', feed.copyOf, {
            root: true
          })
        }
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    }
  }
}
