import { render, staticRenderFns } from "./WizardTypePicker.vue?vue&type=template&id=695a39cc"
import script from "./WizardTypePicker.vue?vue&type=script&lang=js"
export * from "./WizardTypePicker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports