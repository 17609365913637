<template>
  <wizard-type-select v-model="type" />
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import WizardTypeSelect from '@/modules/core/components/WizardTypeSelect'

export default {
  components: {
    WizardTypeSelect
  },
  computed: {
    ...mapFields('wizard', {
      type: 'type'
    })
  }
}
</script>
