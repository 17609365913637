class PermissionManager {
  static makePermissions (rules) {
    const ruleByKeys = rules.reduce((acc, rule) => {
      acc[rule.key] = rule
      return acc
    }, {})
    const getRelatedRules = rule => {
      const related = [rule.key]
      let it = rule
      while (ruleByKeys[it.parent]) {
        related.push(it.parent)
        it = ruleByKeys[it.parent]
      }
      return related
    }
    const getPermission = rule => Math.max.apply(
      null,
      getRelatedRules(rule).map(key => ruleByKeys[key]?.permission || 0)
    )
    return rules.map(rule => ({
      title: rule.title,
      key: rule.key,
      parent: rule.parent,
      permission: getPermission(rule)
    }))
  }

  constructor () {
    this.permissions = []
  }

  isAllowed (permission, resource) {
    return this.permissions.some(rule => (
      rule.key === resource &&
      rule.permission >= permission
    ))
  }

  isAllowedPattern (permission, pattern) {
    return this.permissions.some(rule => (
      pattern.test(rule.key) &&
      rule.permission >= permission
    ))
  }

  setPermissions (permissions) {
    this.permissions = permissions
  }

  reset () {
    this.permissions = []
  }
}

export default PermissionManager
