import Layout from '@/modules/core/components/Layout'

export const makeRoutes = ({ acl, i18n }) => {
  return [
    {
      path: '/archive',
      component: Layout,
      redirect: { name: 'archive' },
      meta: {
        module: 'archive',
        getTitle: () => i18n.t('archive.router.archive'),
        icon: 'archive',
        order: 12,
        navbar: true,
        aclChecker: () => acl.isAllowed(acl.P.R, acl.R.Archive)
      },
      children: [
        {
          path: '',
          name: 'archive',
          component: () => import('@/modules/archive/pages/Archive'),
          meta: {
            scope: 'archive',
            module: 'archive',
            getTitle: () => i18n.t('archive.router.archive'),
            icon: 'archive'
          }
        },
        {
          path: 'tank/:tankId',
          component: () => import('@/modules/archive/components/ArchivedTankLayout'),
          meta: {
            scope: 'archive',
            module: 'archive',
            getTitle: () => i18n.t('archive.router.archive'),
            icon: 'archive'
          },
          children: [
            {
              path: '',
              name: 'archive.tank',
              redirect: 'fish',
              component: () => import('@/modules/archive/pages/ArchivedTank'),
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            },
            {
              path: 'fish-info',
              name: 'archive.tank.fishInfo',
              component: () => import('@/modules/archive/pages/ArchivedTankFishInfo'),
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            },
            {
              path: 'fish',
              component: () => import('@/modules/archive/pages/ArchivedTankFish'),
              name: 'archive.tank.fish',
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            },
            {
              path: 'density',
              component: () => import('@/modules/archive/pages/ArchivedTankFishDensity'),
              name: 'archive.tank.density',
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            },
            {
              path: 'water',
              component: () => import('@/modules/archive/pages/ArchivedTankWater'),
              name: 'archive.tank.water',
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            },
            {
              path: 'mortality',
              component: () => import('@/modules/archive/pages/ArchivedTankFishMortality'),
              name: 'archive.tank.mortality',
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            },
            {
              path: 'catch',
              component: () => import('@/modules/archive/pages/ArchivedTankFishCatch'),
              name: 'archive.tank.catch',
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            },
            {
              path: 'seeding',
              component: () => import('@/modules/archive/pages/ArchivedTankFishSeeding.vue'),
              name: 'archive.tank.seeding',
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            },
            {
              path: 'feeding',
              component: () => import('@/modules/archive/pages/ArchivedTankFeeding'),
              name: 'archive.tank.feeding',
              meta: {
                scope: 'archive',
                module: 'archive',
                getTitle: () => i18n.t('archive.router.archive'),
                icon: 'archive'
              }
            }
          ]
        }
      ]
    }
  ]
}
