import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import {
  fetchFactFeedingConsumptionInPeriodByTanks,
  fetchPlanFeedingConsumptionInPeriodByTanks
} from '@/modules/supervisor/api/feeding'
import {
  startOfMonth,
  endOfMonth
} from '@/utils/date'

export default {
  namespaced: true,
  state: {
    isVisible: false,
    isLoadingFact: false,
    period: [
      startOfMonth(new Date()).toISOString(),
      endOfMonth(new Date()).toISOString()
    ],
    tankIds: [],
    factConsumption: [],
    planConsumption: []
  },
  getters: {
    getField,
    consumption: state => {
      const consumption = {}
      for (const row of state.factConsumption) {
        if (!consumption[row.feedProducerId]) {
          consumption[row.feedProducerId] = {
            plan: 0,
            fact: 0
          }
        }
        consumption[row.feedProducerId].fact = row.consumption
      }
      for (const row of state.planConsumption) {
        if (!consumption[row.feedProducerId]) {
          consumption[row.feedProducerId] = {
            plan: 0,
            fact: 0
          }
        }
        consumption[row.feedProducerId].plan = row.consumption
      }
      const rv = []
      for (const feedProducerId in consumption) {
        rv.push({
          feedProducerId,
          ...consumption[feedProducerId]
        })
      }
      return rv
    }
  },
  mutations: {
    updateField,

    SET_LOADING_FACT: (state, isLoading) => {
      Vue.set(state, 'isLoadingFact', isLoading)
    },
    SET_LOADING_PLAN: (state, isLoading) => {
      Vue.set(state, 'isLoadingPlan', isLoading)
    },
    SET_PERIOD: (state, period) => {
      Vue.set(state, 'period', period)
    },
    SET_TANK_IDS: (state, tankIds) => {
      Vue.set(state, 'tankIds', tankIds)
    },
    RESET_FACT_CONSUMPTION: state => {
      Vue.set(state, 'factConsumption', [])
    },
    RESET_PLAN_CONSUMPTION: state => {
      Vue.set(state, 'planConsumption', [])
    },
    SET_FACT_CONSUMPTION: (state, factConsumption) => {
      Vue.set(state, 'factConsumption', factConsumption)
    },
    SET_PLAN_CONSUMPTION: (state, planConsumption) => {
      Vue.set(state, 'planConsumption', planConsumption)
    },
    SHOW: state => {
      Vue.set(state, 'isVisible', true)
    },
    CLOSE: state => {
      Vue.set(state, 'isVisible', false)
    }
  },
  actions: {
    async fetchFactFeedingConsumptionInPeriodByTanks ({ commit }, {
      tankIds, start, end
    }) {
      commit('RESET_FACT_CONSUMPTION')
      if (tankIds.length === 0) {
        return
      }
      commit('SET_LOADING_FACT', true)
      try {
        var response = await fetchFactFeedingConsumptionInPeriodByTanks({
          tankIds,
          start,
          end
        })
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_FACT', false)
      }
      commit('SET_FACT_CONSUMPTION', Object.freeze(response.data))
    },
    async fetchPlanFeedingConsumptionInPeriodByTanks ({ commit }, {
      tankIds, start, end
    }) {
      commit('RESET_PLAN_CONSUMPTION')
      if (tankIds.length === 0) {
        return
      }
      commit('SET_LOADING_PLAN', true)
      try {
        const response = await fetchPlanFeedingConsumptionInPeriodByTanks({
          tankIds,
          start,
          end
        })
        commit('SET_PLAN_CONSUMPTION', Object.freeze(response.data))
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_PLAN', false)
      }
    }
  }
}
