import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,

  state: {
    isVisible: false,
    type: null
  },

  getters: {
    getField
  },

  mutations: {
    updateField,
    SHOW_WIZARD: state => {
      state.isVisible = true
    },
    SET_TYPE: (state, type) => {
      state.type = type
    },
    OPEN_WIZARD: (state, type) => {
      state.type = type
      state.isVisible = true
    }
  }
}
