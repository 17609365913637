<template>
  <div>
    <el-button icon="mdi mdi-help" circle size="mini" @click="openHelp" />
    <el-drawer
      id="help-panel"
      :title="$t('core.help.title')"
      :visible.sync="isVisible"
      direction="rtl"
      size="660px"
      :before-close="beforeClose"
      @closed="onClosed"
    >
      <template #title>
        <div class="tab-list">
          <a
            v-for="tabName in tabNames"
            :key="tabName"
            :class="{
              'tab-link': true,
              'tab-link--active': tabName === tab
            }"
            @click="() => selectTab(tabName)"
          >
            {{ $t(`core.help.${tabName}`) }}
          </a>
        </div>
      </template>
      <div class="help-wrapper">
        <div class="help-content">
          <template v-if="tab === 'aboutPage'">
            <help-message-box :message-key="$route.name" />
          </template>
          <template v-else-if="tab === 'faq'">
            <help-message-box message-key="global.faq" />
          </template>
          <template v-else-if="tab === 'functionalityMap'">
            <help-site-map />
          </template>
          <template v-else-if="tab === 'editor' && isEditor">
            <help-editor />
          </template>
        </div>

        <div class="help-footer">
          <div v-if="SUPPORT_EMAIL" class="footer-item">
            {{ $t('core.help.techSupport') }}
            <a :href="`mailto:${SUPPORT_EMAIL}`" class="link">
              {{ SUPPORT_EMAIL }}
            </a>
          </div>
          <div v-if="USER_MANUAL_URL" class="footer-item">
            <a :href="USER_MANUAL_URL" target="_blank" class="link">
              {{ $t('core.help.userManual') }}
            </a>
          </div>
          <div v-if="USER_AGREEMENT_URL" class="footer-item">
            <a :href="USER_AGREEMENT_URL" target="_blank" class="link">
              {{ $t('core.help.userAgreement') }}
            </a>
          </div>
          <div v-if="PERSONAL_DATA_PROCESSING_CONSENT_URL" class="footer-item">
            <a :href="PERSONAL_DATA_PROCESSING_CONSENT_URL" target="_blank" class="link">
              {{ $t('core.help.personalDataProcessingConsent') }}
            </a>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Drawer } from 'element-ui'
import HelpSiteMap from '@/modules/core/components/HelpSiteMap'
import HelpMessageBox from '@/modules/core/components/HelpMessageBox'
import HelpEditor from '@/modules/core/components/HelpEditor'
import acl from '@/acl'

export default {
  components: {
    ElDrawer: Drawer,
    HelpSiteMap,
    HelpMessageBox,
    HelpEditor
  },
  data () {
    return {
      isVisible: false,
      tab: ''
    }
  },
  computed: {
    tabNames () {
      const names = ['aboutPage', 'faq', 'functionalityMap']
      if (this.isEditor) {
        names.push('editor')
      }
      return names
    },
    isEditor () {
      return acl.isSuperuser()
    },
    SUPPORT_EMAIL () {
      return this.helpMessages.SUPPORT_EMAIL
    },
    USER_MANUAL_URL () {
      return this.helpMessages.USER_MANUAL_URL
    },
    USER_AGREEMENT_URL () {
      return this.helpMessages.USER_AGREEMENT_URL
    },
    PERSONAL_DATA_PROCESSING_CONSENT_URL () {
      return this.helpMessages.PERSONAL_DATA_PROCESSING_CONSENT_URL
    },
    helpMessages () {
      return this.$store.getters['help/messageByNames']
    },
    isHelpBusy () {
      return this.$store.state.help.isBusy
    }
  },
  methods: {
    openHelp () {
      this.tab = 'aboutPage'
      this.isVisible = true
    },
    async selectTab (tabName) {
      const isDone = !this.isHelpBusy || await this.isReadyToClose()
      if (!isDone) {
        return
      }
      if (this.isHelpBusy) {
        this.setIsBusy(false)
      }
      this.tab = tabName
    },
    async isReadyToClose () {
      try {
        await this.$confirm(
          this.$t('core.help.discardChangesAndClose?'),
          this.$t('core.statuses.warning'),
          {
            confirmButtonText: this.$t('core.misc.close'),
            confirmButtonClass: 'el-button--warning',
            cancelButtonText: this.$t('core.misc.cancel'),
            type: 'warning'
          }
        )
        return true
      } catch {
        return false
      }
    },
    async beforeClose (done) {
      if (!this.isHelpBusy) {
        done(true)
        return
      }
      const isDone = await this.isReadyToClose()
      if (isDone) {
        this.setIsBusy(false)
      }
      done(isDone)
    },
    onClosed () {
      this.tab = ''
    },
    setIsBusy (isBusy) {
      this.$store.commit('help/SET_IS_BUSY', isBusy)
    }
  }
}
</script>

<style lang="scss">
#help-panel {
  .tab-list {
    flex: 1;
    .tab-link {
      font-weight: 400;
      cursor: pointer;
      &--active {
        font-weight: 600;
        color: #fff;
        font-size: 1.2em;
      }
      &:hover {
        color: #fff;
      }
    }
    .tab-link + .tab-link {
      margin-left: 1em;
    }
  }
  .el-drawer__header {
    background: var(--color-primary-600);
    color: #fff;
    font-weight: 600;
    margin: 0;
    padding: 1em;
  }
  .el-drawer__body {
    flex: 1;
  }
  .help-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .help-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .pages-list {
      padding: 1em;
    }
    .el-page-header {
      margin: 1em;
    }
    .v-note-wrapper .v-note-panel .v-note-show .v-show-content,
    .v-note-wrapper .v-note-panel .v-note-show .v-show-content-html {
      padding: .5em 1em;
    }
  }
  .help-footer {
    background: var(--color-primary-600);
    color: #fff;
    font-weight: 500;
    font-size: .8em;
    margin: 0;
    padding: 1em;
    .link {
      color: #fff;
    }
  }
}
</style>
