<template>
  <section class="wizard">
    <header class="wizard-header">
      <slot name="header">
        <h1>
          <slot name="title">
            {{ $t('core.wizard.title') }}
          </slot>
        </h1>
        <wizard-type-picker />
      </slot>
      <slot name="toolbar" />
    </header>
    <main class="wizard-body">
      <slot />
    </main>
    <footer class="wizard-footer">
      <slot name="footer">
        <close-button @click="closeWizard" />
      </slot>
    </footer>
  </section>
</template>

<script>
import WizardMix from '@/modules/core/mixins/wizard'

export default {
  mixins: [WizardMix]
}
</script>
