import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import acl from '@/acl'
import * as api from '@/modules/stocks/api/stocks'
import { getUnionTanksBySite } from '@/modules/core/use/factory'

export default {
  namespaced: true,
  state: {
    all: [],
    isLimitedAccess: false,
    isLoading: false
  },
  getters: {
    getField,

    names: state => {
      return state.all.reduce((acc, stock) => {
        acc[stock.id] = stock.name
        return acc
      }, {})
    },
    bindingByIds: (state, getters, rootState, rootGetters) => {
      const siteNames = rootGetters['factory/sites/names']
      const tankLocations = rootGetters['factory/tanks/locations']

      return state.all.reduce((acc, stock) => {
        const unionTanksBySite = getUnionTanksBySite(
          stock.tankIds,
          rootState.factory.allContent
        )
        const binding = []
        for (const siteId of unionTanksBySite.sites) {
          binding.push({
            key: siteId,
            text: siteNames[siteId]
          })
        }
        for (const tankId of unionTanksBySite.tanks) {
          binding.push({
            key: tankId,
            text: tankLocations[tankId]
          })
        }
        acc[stock.id] = binding
        return acc
      }, {})
    },
    userStocks: state => {
      return state.all.filter(stock => acl.isAllowed(
        acl.P.R,
        acl.R.c(acl.R.Stocks, acl.R.Stock(stock.id))
      ))
    },
    stocksForTransfers: state => {
      return state.all.filter(stock => acl.isAllowed(
        acl.P.W,
        acl.R.c(acl.R.Stocks, acl.R.Stock(stock.id))
      ))
    }
  },
  mutations: {
    updateField,

    SET_LOADING_STATUS: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_ALL: (state, data) => {
      Vue.set(state, 'all', Object.freeze(data))
    },
    SET_IS_LIMITED_ACCESS: (state, isLimitedAccess) => {
      Vue.set(state, 'isLimitedAccess', isLimitedAccess)
    }
  },
  actions: {
    async fetchStocks ({ commit }) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.fetchStocks()
        commit('SET_ALL', response.data.stocks)
        commit('SET_IS_LIMITED_ACCESS', response.data.isLimitedAccess)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async createStock ({ commit, dispatch }, data) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.createStock(data)
        await acl.fetchPermissions()
        await dispatch('fetchStocks')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async updateStockById ({ commit, dispatch }, { id, data }) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.updateStockById(id, data)
        await dispatch('fetchStocks')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async deleteStockById ({ commit, dispatch }, id) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.deleteStockById(id)
        await dispatch('fetchStocks')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    }
  }
}
