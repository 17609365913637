import request from '@/utils/request'

const PREFIX = '/api/core/factory/map'

export function fetchFactoryMap () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function saveFactoryMap (map) {
  return request({
    url: PREFIX,
    method: 'post',
    data: map
  })
}

export function updateFactoryMapBackgroundImage (imageId) {
  return request({
    url: `${PREFIX}/background-image`,
    method: 'put',
    data: { imageId }
  })
}

export function uploadFactoryMapBackgroundImage (formData) {
  return request({
    url: `${PREFIX}/background-image/upload`,
    method: 'post',
    data: formData
  })
}

export default {
  fetchFactoryMap,
  saveFactoryMap,
  updateFactoryMapBackgroundImage,
  uploadFactoryMapBackgroundImage
}
