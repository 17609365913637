import Vue from 'vue'
import Router from 'vue-router'
import { i18n } from '@/i18next'
import acl from '@/acl'
import store from '@/store'
import { makeRoutes as makeCoreRoutes } from '@/modules/core/router'
import { makeRoutes as makeStocksRoutes } from '@/modules/stocks/router'
import { makeRoutes as makeSupervisorRoutes } from '@/modules/supervisor/router'
import { makeRoutes as makeIotRoutes } from '@/modules/iot/router'
import { makeRoutes as makeArchiveRoutes } from '@/modules/archive/router'
import { saveAnalyticsEvent } from '@/plugins/analytics'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: []
})

const addRoute = route => router.addRoute(route)

makeCoreRoutes({ i18n, acl }).forEach(addRoute)
makeStocksRoutes({ i18n, acl }).forEach(addRoute)
makeSupervisorRoutes({ i18n, acl }).forEach(addRoute)
makeIotRoutes({ i18n, acl }).forEach(addRoute)
makeArchiveRoutes({ i18n, acl }).forEach(addRoute)

router.beforeEach(beforeEachHook)

async function beforeEachHook (to, from, next) {
  if (to.name && from?.name !== to.name) {
    saveAnalyticsEvent({
      timestamp: new Date().toISOString(),
      event: 'navigation',
      payload: {
        fromScope: from?.meta?.scope || '',
        fromRoute: from?.name,
        toScope: to.meta?.scope || '',
        toRoute: to.name
      }
    })
  }
  if (store.state.core.app.isReady && to.meta?.getTitle) {
    document.title = to.meta.getTitle()
  }
  if (to.meta?.public) {
    return next()
  }
  if (!store.state.auth.me) {
    try {
      store.commit('core/app/ADD_LOADING_ITEM', 'fetchUserInfo')
      await store.dispatch('auth/getInfoByMe')
      if (to.name === 'login') {
        return next({ path: '/' })
      }
    } catch (error) {
      if (to.name === 'login') {
        return next()
      }
      return next({
        name: 'login',
        replace: true,
        query: { redirect: to.fullPath }
      })
    } finally {
      store.commit('core/app/REMOVE_LOADING_ITEM', 'fetchUserInfo')
    }
  }

  const privateRoutes = to.matched.filter(route => route.meta?.aclChecker)
  if (privateRoutes.some(route => !route.meta.aclChecker(to))) {
    return next({ name: 'error403', replace: true })
  }
  if (to.matched.length === 0) {
    return next({ name: 'error404', replace: true })
  }

  if (!store.state.core.app.isReady) {
    await store.dispatch('core/app/bootstrap')
    if (to.meta?.getTitle) {
      document.title = to.meta.getTitle()
    }
  }
  if (
    !store.state.core.factories.isLoaded &&
    !store.state.core.factories.isLoading
  ) {
    store.dispatch('core/factories/initFactories')
  }

  return next()
}

export function getNavbarItems () {
  const routes = router.getRoutes()
    .filter(route => route.meta?.navbar)
    .filter(route => route.meta.aclChecker
      ? route.meta.aclChecker()
      : true
    )
    .map(route => ({
      order: route.meta.order,
      path: route.path,
      icon: route.meta.icon,
      label: route.meta.getTitle()
    }))
  routes.sort((a, b) => a.order - b.order)
  return routes
}

export default router
