import { fetchFactoryContentInPeriod } from '@/modules/supervisor/api/production'

export default {
  namespaced: true,
  state: {
    tanks: [],
    sites: [],
    virtualSites: []
  },
  getters: {
    visibleSites: (state, getters, rootState) => {
      return rootState.core.app.isEmptySitesVisible
        ? state.sites
        : state.sites.filter(site => site.hasFish)
    },
    visibleVirtualSites: (state, getters, rootState) => {
      return rootState.core.app.isEmptySitesVisible
        ? state.virtualSites
        : state.virtualSites.filter(site => site.hasFish)
    },
    tankLocations: (state) => {
      return state.tanks.reduce((locations, tank) => {
        locations[tank.id] = tank.location
        return locations
      }, {})
    }
  },
  mutations: {
    SET_FACTORY_CONTENT: (state, payload) => {
      state.tanks = payload.tanks.map(Object.freeze)
      state.sites = payload.sites.map(Object.freeze)
      state.virtualSites = payload.virtualSites.map(Object.freeze)
    },
    RESET_FACTORY_CONTENT: (state) => {
      state.tanks = []
      state.sites = []
      state.virtualSites = []
    }
  },
  actions: {
    async fetchFactoryContentInPeriod ({ commit }, { periodStart, periodEnd }) {
      try {
        const response = await fetchFactoryContentInPeriod(periodStart, periodEnd)
        commit('SET_FACTORY_CONTENT', response.data)
      } catch (error) {
        commit('RESET_FACTORY_CONTENT')
        return Promise.reject(error)
      }
    }
  }
}
