import { saveAs } from 'file-saver'

export const readFile = async function (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      resolve(e.target.result)
    }
    reader.onerror = function () {
      reject(reader.error)
    }
    reader.readAsText(file)
  })
}

export const saveFileAs = saveAs
