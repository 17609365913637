import request from '@/utils/request'

const PREFIX = '/api/supervisor/feeding'

export function fetchFactFeedingConsumptionInPeriodByTanks ({
  tankIds, start, end
}) {
  return request({
    url: `${PREFIX}/consumption/fact/filter`,
    method: 'post',
    data: { tankIds, start, end }
  })
}

export function fetchPlanFeedingConsumptionInPeriodByTanks ({
  tankIds, start, end
}) {
  return request({
    url: `${PREFIX}/consumption/plan/filter`,
    method: 'post',
    data: { tankIds, start, end }
  })
}
