import Vue from 'vue'
import acl from '@/acl'
import * as api from '@/modules/core/api/auth'

export default {
  namespaced: true,
  state: {
    me: null
  },
  mutations: {
    SET_ME: (state, info) => {
      Vue.set(state, 'me', info)
    }
  },
  actions: {
    async loginByUsername ({ state, commit }, userInfo) {
      const data = {
        login: userInfo.username.trim(),
        password: userInfo.password
      }
      try {
        const response = await api.loginByUsername(data)
        const user = response.data
        commit('SET_ME', user)
        await acl.initByUser(user)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getInfoByMe ({ state, commit, dispatch }) {
      try {
        const response = await api.getInfoByMe()
        const user = response.data
        commit('SET_ME', user)
        await acl.initByUser(user)
        return user
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async logOut ({ commit }) {
      commit('SET_ME', null)
      commit('core/factories/SET_IS_LOADED', false, { root: true })
      commit('core/app/SET_IS_READY', false, { root: true })
      acl.reset()
      try {
        await api.logout()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
