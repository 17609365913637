<template>
  <router-view />
</template>

<script>
import { parseError } from '@/utils/request'

export default {
  name: 'IotDevices',

  created () {
    this.fetchThermoOximeters()
  },

  methods: {
    async fetchThermoOximeters () {
      try {
        await this.$store.dispatch('iot/thermoOximeter/fetchAll')
      } catch (e) {
        this.$notify.error({
          title: this.$t('iot.thermoOximeter.fetchAllOperation'),
          message: parseError(e)
        })
      }
    }
  }
}
</script>
