import * as api from '@/modules/core/api/systemFeedProducers'
import {
  makeNameForFeedProducer,
  feedProducersComparatorByName
} from '@/modules/core/use/feedProducer'

export default {
  namespaced: true,
  state: {
    all: [],
    isLoading: false,
    summary: []
  },
  getters: {
    names: state => {
      return state.all.reduce((names, producer) => {
        names[producer.id] = makeNameForFeedProducer(producer)
        return names
      }, {})
    },
    byIds: state => {
      return state.all.reduce((acc, feedProducer) => {
        acc[feedProducer.id] = feedProducer
        return acc
      }, {})
    },
    getById: state => feedProducerId => {
      return state.all.find(feedProducer => {
        return feedProducer.id === feedProducerId
      })
    },
    handbookByIds: state => {
      const handbooks = state.all.map(producer => producer.handbooks).flat()
      return handbooks.reduce((acc, handbook) => {
        acc[handbook.id] = handbook
        return acc
      }, {})
    },
    handbookNames: state => {
      const handbooks = state.all.map(producer => producer.handbooks).flat()
      return handbooks.reduce((acc, handbook) => {
        acc[handbook.id] = handbook.name
        return acc
      }, {})
    }
  },
  mutations: {
    SET_LOADING_STATUS: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_ALL: (state, feedProducers) => {
      const sortedFeedProducers = feedProducers.slice()
      sortedFeedProducers.sort(feedProducersComparatorByName)
      state.all = sortedFeedProducers
    },
    MAKE_COPY_OF: (state, feedId) => {
      const idx = state.all.findIndex((feed) => feed.id === feedId)
      if (idx !== -1) {
        state.all.splice(idx, 1, {
          ...state.all[idx],
          isCopied: true
        })
      }
    },
    DELETE_COPY_OF: (state, feedId) => {
      const idx = state.all.findIndex((feed) => feed.id === feedId)
      if (idx !== -1) {
        state.all.splice(idx, 1, {
          ...state.all[idx],
          isCopied: false
        })
      }
    }
  },
  actions: {
    async fetchFeedProducers ({ commit }) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.fetchSystemFeedProducers()
        const feedProducers = response.data
        commit('SET_ALL', feedProducers)
        return feedProducers
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async createFeedProducer ({ commit, dispatch }, feedProducer) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.createSystemFeedProducer(feedProducer)
        const feedProducerId = response.data
        await dispatch('fetchFeedProducers')
        return feedProducerId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async updateFeedProducerById ({ commit, dispatch }, { id, data }) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.updateSystemFeedProducerById(id, data)
        await dispatch('fetchFeedProducers')
        await dispatch(
          'core/feedProducers/fetchFeedProducers',
          null,
          { root: true }
        )
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async deleteFeedProducerById ({ commit, dispatch }, id) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.deleteSystemFeedProducerById(id)
        await dispatch('fetchFeedProducers')
        await dispatch(
          'core/feedProducers/fetchFeedProducers',
          null,
          { root: true }
        )
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async copyFeedProducerById ({ commit }, id) {
      try {
        await api.copySystemFeedProducerById(id)
        commit('MAKE_COPY_OF', id)
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
