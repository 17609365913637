import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import {
  startOfDay,
  endOfDay,
  sub as subToDate
} from '@/utils/date'
import * as api from '@/modules/core/api/events'
import { SCOPES } from '@/plugins/io'

const makeDefaultPeriod = date => [
  startOfDay(subToDate(date, { days: 6 })).toISOString(),
  endOfDay(date).toISOString()
]

const makeFilterStruct = () => ({
  location: [],
  userIds: [],
  withCommentOnly: false,
  scopes: SCOPES.slice()
})

export default {
  namespaced: true,
  state: {
    period: makeDefaultPeriod(new Date()),
    events: [],
    totalEvents: 0,
    filter: makeFilterStruct(),
    pagination: {
      currentPage: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50]
    }
  },
  getters: {
    getField

  },
  mutations: {
    updateField,

    CHANGE_PERIOD: (state, period) => {
      Vue.set(state, 'period', period)
    },
    RESET_PERIOD: state => {
      Vue.set(state, 'period', makeDefaultPeriod(new Date()))
    },
    SET_EVENTS: (state, events) => {
      Vue.set(state, 'events', events)
    },
    SET_TOTAL_EVENTS: (state, totalEvents) => {
      Vue.set(state, 'totalEvents', totalEvents)
    },
    SET_CURRENT_PAGE: (state, page) => {
      Vue.set(state.pagination, 'currentPage', page)
    },
    SET_PAGE_SIZE: (state, pageSize) => {
      Vue.set(state.pagination, 'pageSize', pageSize)
    },
    RESET_FILTER: state => {
      Vue.set(state, 'filter', makeFilterStruct())
    }
  },
  actions: {
    async fetchEventsInPeriod ({ commit }, { periodStart, periodEnd }) {
      let response
      try {
        response = await api.fetchEventsInPeriod({ periodStart, periodEnd })
      } catch (e) {
        return Promise.reject(e)
      }
      const events = response.data
      commit('SET_EVENTS', events)
      return events
    },
    async fetchEventsByFilter ({ state, commit, dispatch }, filterParams) {
      try {
        const response = await api.fetchEventsByFilter(filterParams)
        commit('SET_EVENTS', response.data.events)
        commit('SET_TOTAL_EVENTS', response.data.totalEvents)
        commit('SET_CURRENT_PAGE', response.data.page)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
}
