import request from '@/utils/request'

const PREFIX = '/api/supervisor/production-plans'

export function fetchPlansForAvailableTanks ({ periodStart, periodEnd }) {
  return request({
    url: PREFIX,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchPlansInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/filter`,
    method: 'post',
    data: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchPlanById (planId) {
  return request({
    url: `${PREFIX}/${planId}`,
    method: 'get'
  })
}

export function createPlan (plan) {
  return request({
    url: `${PREFIX}`,
    method: 'post',
    data: plan
  })
}

export function updatePlanById (planId, plan) {
  return request({
    url: `${PREFIX}/${planId}`,
    method: 'put',
    data: plan
  })
}

export function deletePlanById (planId) {
  return request({
    url: `${PREFIX}/${planId}`,
    method: 'delete'
  })
}

export function saveCorrection (correction) {
  return request({
    url: `${PREFIX}/correction`,
    method: 'post',
    data: correction
  })
}

export function deleteCorrectionById (correctionId) {
  return request({
    url: `${PREFIX}/correction/${correctionId}`,
    method: 'delete'
  })
}
