import request from '@/utils/request'

const PREFIX = '/api/core/indicators/fish/change'

export function fetchFishChangeHistoryInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchTankFishHistoryChangingInPeriod ({ tankId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/tank`,
    method: 'get',
    params: {
      tankId: tankId,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchFishChangesInPeriodByTankIds ({ tankIds, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/tanks`,
    method: 'post',
    data: {
      tankIds,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchTankOnlyFishAmountHistoryInPeriod ({ tankId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/onlyfishamount`,
    method: 'get',
    params: {
      tankId: tankId,
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchTankOnlyFishBiomassHistoryInPeriod ({ tankId, periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}/onlyfishbiomass`,
    method: 'get',
    params: {
      tankId: tankId,
      start: periodStart,
      end: periodEnd
    }
  })
}
