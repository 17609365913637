import {
  fetchMedicines,
  createMedicine,
  updateMedicineById,
  deleteMedicineById
} from '@/modules/core/api/medicines'

export default {
  namespaced: true,
  state: {
    types: [
      'drug',
      'vitamin',
      'disinfection'
    ],
    all: [],
    isLoading: false
  },
  getters: {
    names: state => {
      return state.all.reduce((names, medicine) => {
        names[medicine.id] = medicine.name
        return names
      }, {})
    },
    byIds: state => {
      return state.all.reduce((acc, medicine) => {
        acc[medicine.id] = medicine
        return acc
      }, {})
    }
  },
  mutations: {
    SET_ALL: (state, all) => {
      state.all = all
    },
    SET_IS_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    }
  },
  actions: {
    async fetchMedicines ({ commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const { data } = await fetchMedicines()
        commit('SET_ALL', data)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async createMedicine ({ commit, dispatch }, medicine) {
      try {
        commit('SET_IS_LOADING', true)
        const { data: medicineId } = await createMedicine(medicine)
        await dispatch('fetchMedicines')
        return medicineId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async updateMedicineById ({ commit, dispatch }, { id, data }) {
      try {
        commit('SET_IS_LOADING', true)
        await updateMedicineById(id, data)
        await dispatch('fetchMedicines')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async deleteMedicineById ({ commit, dispatch }, id) {
      try {
        commit('SET_IS_LOADING', true)
        await deleteMedicineById(id)
        await dispatch('fetchMedicines')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    }
  }
}
