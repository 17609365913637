import {
  fetchCommonReasons,
  createCommonReason,
  updateCommonReasonById,
  deleteCommonReasonById
} from '@/modules/core/api/reasons'

export default {
  namespaced: true,
  state: {
    scopes: [
      'seeding',
      'mortality',
      'catch',
      'fishWeight',
      'moving',
      'stockOutcome'
    ],
    saleReason: null,
    correctionReason: null,
    emptyReason: 'Без причины',
    all: [],
    isLoading: false
  },
  getters: {
    labels: state => {
      return state.all.reduce((names, reason) => {
        names[reason.id] = reason.label
        return names
      }, {})
    },
    byIds: state => {
      return state.all.reduce((acc, reason) => {
        acc[reason.id] = reason
        return acc
      }, {})
    },
    byScopes: state => {
      return state.all.reduce((acc, item) => {
        item.scopes.forEach(scope => {
          acc[scope].push(item)
        })
        return acc
      }, state.scopes.reduce((acc, scope) => {
        acc[scope] = []
        return acc
      }, {}))
    }
  },
  mutations: {
    SET_ALL: (state, data) => {
      state.all = data.reasons
      state.saleReason = data.saleReason
      state.correctionReason = data.correctionReason
    },
    SET_IS_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    }
  },
  actions: {
    async fetchCommonReasons ({ commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const { data } = await fetchCommonReasons()
        commit('SET_ALL', data)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async createCommonReason ({ commit, dispatch }, reason) {
      try {
        commit('SET_IS_LOADING', true)
        const { data: reasonId } = await createCommonReason(reason)
        await dispatch('fetchCommonReasons')
        return reasonId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async updateCommonReasonById ({ commit, dispatch }, { id, data }) {
      try {
        commit('SET_IS_LOADING', true)
        await updateCommonReasonById(id, data)
        await dispatch('fetchCommonReasons')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async deleteCommonReasonById ({ commit, dispatch }, id) {
      try {
        commit('SET_IS_LOADING', true)
        await deleteCommonReasonById(id)
        await dispatch('fetchCommonReasons')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    }
  }
}
