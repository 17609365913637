import Vue from 'vue'
import { i18n } from '@/i18next'
import * as api from '@/modules/core/api/factories'

function getOriginFactory () {
  return {
    name: i18n.t('entity.factory'),
    server: window.location.origin
  }
}

export default {
  namespaced: true,
  state: {
    factories: [],
    userFactories: [],
    isLoaded: false,
    isLoading: false
  },
  getters: {
    localFactory: state => {
      const originFactory = getOriginFactory()
      return (
        state.userFactories.find(row => row.server === originFactory.server) ||
        originFactory
      )
    },
    remoteFactories: state => {
      const originFactory = getOriginFactory()
      return state.userFactories.filter(row => row.server !== originFactory.server)
    },
    getById: state => factoryId => {
      return state.factories.find(f => f.id === factoryId)
    }
  },
  mutations: {
    SET_FACTORIES: (state, factories) => {
      Vue.set(state, 'factories', factories)
    },
    SET_USER_FACTORIES: (state, userFactories) => {
      Vue.set(state, 'userFactories', userFactories)
    },
    ADD_FACTORY: (state, factory) => {
      const factories = state.factories.filter(f => f.id !== factory.id)
      factories.push(factory)
      Vue.set(state, 'factories', factories)
    },
    DELETE_FACTORY_BY_ID: (state, factoryId) => {
      const factories = state.factories.filter(f => f.id !== factoryId)
      Vue.set(state, 'factories', factories)
    },
    SET_IS_LOADED: (state, isLoaded) => {
      Vue.set(state, 'isLoaded', isLoaded)
    },
    SET_LOADING: (state, isLoading) => {
      Vue.set(state, 'isLoading', isLoading)
    }
  },
  actions: {
    async initFactories ({ commit, dispatch }, credentials) {
      commit('SET_LOADING', true)
      try {
        const response = await api.fetchFactories()
        const factories = response.data
        commit('SET_FACTORIES', factories)
        commit('SET_IS_LOADED', true)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING', false)
      }
      if (credentials) {
        await dispatch('loginAll', credentials)
      }
      await dispatch('checkoutFactories')
    },
    async fetchFactories ({ commit }) {
      commit('SET_LOADING', true)
      try {
        var response = await api.fetchFactories()
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING', false)
      }
      commit('SET_IS_LOADED', true)
      const factories = response.data
      commit('SET_FACTORIES', factories)
      return factories
    },
    async checkoutFactories ({ state, rootState, commit }) {
      const originFactory = getOriginFactory()
      const userFactories = []
      for (const factory of state.factories) {
        try {
          if (factory.server !== originFactory.server) {
            const response = await api.fetchUserInfo(factory.server)
            const remoteUserLogin = response.data.login
            if (remoteUserLogin !== rootState.auth.me.login) {
              continue
            }
          }
          userFactories.push(factory)
        } catch (e) {
          console.log(`factory ${factory.server} is unavailable`, e)
        }
      }
      if (!userFactories.some(f => f.server === originFactory.server)) {
        userFactories.push(originFactory)
      }
      commit('SET_USER_FACTORIES', userFactories)
    },
    async loginAll ({ state, commit }, credentials) {
      const userFactories = []
      for (const factory of state.factories) {
        try {
          if (factory.server !== window.location.origin) {
            await api.loginIntoFactory(credentials, factory.server)
          }
          userFactories.push(factory)
        } catch (e) {
          console.log(`factory ${factory.server} is unavailable`, e)
        }
      }
      commit('SET_USER_FACTORIES', userFactories)
    },
    async fetchFactoryById ({ commit }, factoryId) {
      commit('SET_LOADING', true)
      try {
        var response = await api.fetchFactoryById(factoryId)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING', false)
      }
      const factory = response.data
      commit('ADD_FACTORY', factory)
      return factory
    },
    async addFactory ({ commit, dispatch }, factory) {
      commit('SET_LOADING', true)
      try {
        var response = await api.addFactory(factory)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING', false)
      }
      await dispatch('fetchFactoryById', response.data)
    },
    async updateFactory ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)
      try {
        await api.updateFactory(params)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING', false)
      }
      await dispatch('fetchFactoryById', params.id)
    },
    async deleteFactoryById ({ commit }, factoryId) {
      commit('SET_LOADING', true)
      try {
        await api.deleteFactoryById(factoryId)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING', false)
      }
      commit('DELETE_FACTORY_BY_ID', factoryId)
    }
  }
}
