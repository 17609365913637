import Vue from 'vue'
import startOfYear from 'date-fns/startOfYear'
import { fetchPreferences, savePreferences } from '@/modules/core/api/preferences'

export default {
  namespaced: true,
  state: {
    all: null
  },
  getters: {
    saleSinceDate: state => {
      if (state.all.userSalesFromUse) {
        return state.all.userSalesFromDate
      }
      return startOfYear(new Date()).toISOString()
    }
  },
  mutations: {
    SET_PREFERENCES: (state, preferences) => {
      Vue.set(state, 'all', preferences)
    }
  },
  actions: {
    async fetchPreferences ({ commit }) {
      try {
        const resp = await fetchPreferences()
        commit('SET_PREFERENCES', resp.data)
        return resp.data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async savePreferences ({ commit }, data) {
      try {
        await savePreferences(data)
        commit('SET_PREFERENCES', data)
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
}
