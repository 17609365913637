import request from '@/utils/request'

const PREFIX = '/api/stocks'

export function fetchStocks () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function createStock (data) {
  return request({
    url: PREFIX,
    method: 'post',
    data
  })
}

export function updateStockById (id, data) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    data
  })
}

export function deleteStockById (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}

export function fetchStockStateAt (id, timestamp, category) {
  return request({
    url: `${PREFIX}/${id}/state`,
    method: 'get',
    params: {
      timestamp,
      category
    }
  })
}

export function fetchStocksFeedsStateAt (timestamp) {
  return request({
    url: `${PREFIX}/state/feeds`,
    method: 'get',
    params: {
      timestamp
    }
  })
}

export function fetchStocksMedicinesStateAt (timestamp) {
  return request({
    url: `${PREFIX}/state/medicines`,
    method: 'get',
    params: {
      timestamp
    }
  })
}
