import * as api from '@/modules/core/api/systemFeedingPlans'

export default {
  namespaced: true,

  state: {
    all: [],
    isLoading: false
  },

  getters: {
    feedingPlanByIds: state => {
      return state.all.reduce((acc, feedingPlan) => {
        acc[feedingPlan.id] = feedingPlan
        return acc
      }, {})
    },
    names: state => {
      return state.all.reduce((acc, feedingPlan) => {
        acc[feedingPlan.id] = feedingPlan.name
        return acc
      }, {})
    }
  },

  mutations: {
    SET_LOADING_STATUS: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_ALL: (state, feedingPlans) => {
      state.all = feedingPlans
    },
    MAKE_COPY_OF: (state, planId) => {
      const idx = state.all.findIndex((plan) => plan.id === planId)
      if (idx !== -1) {
        state.all.splice(idx, 1, {
          ...state.all[idx],
          isCopied: true
        })
      }
    },
    DELETE_COPY_OF: (state, planId) => {
      const idx = state.all.findIndex((plan) => plan.id === planId)
      if (idx !== -1) {
        state.all.splice(idx, 1, {
          ...state.all[idx],
          isCopied: false
        })
      }
    }
  },

  actions: {
    async fetchFeedingPlans ({ commit }) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.fetchSystemFeedingPlans()
        const feedingPlans = response.data
        commit('SET_ALL', feedingPlans)
        return feedingPlans
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async createFeedingPlan ({ commit, dispatch }, data) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.createSystemFeedingPlan(data)
        const feedingPlanId = response.data
        await dispatch('fetchFeedingPlans')
        return feedingPlanId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async updateFeedingPlanById ({ commit, dispatch }, { data, id }) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.updateSystemFeedingPlanById(id, data)
        await dispatch('fetchFeedingPlans')
        await dispatch(
          'core/feedingPlans/fetchFeedingPlans',
          null,
          { root: true }
        )
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async deleteFeedingPlanById ({ commit, dispatch }, id) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.deleteSystemFeedingPlanById(id)
        await dispatch('fetchFeedingPlans')
        await dispatch(
          'core/feedingPlans/fetchFeedingPlans',
          null,
          { root: true }
        )
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async copyFeedingPlanById ({ commit }, id) {
      try {
        const { data } = await api.copySystemFeedingPlanById(id)
        commit('MAKE_COPY_OF', id)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
