<template>
  <mavon-editor
    ref="mavon"
    v-model="content"
    v-bind="mavenProps"
    class="mavon-editor"
    @save="onSave"
    @imgAdd="onImgAdd"
  >
    <template #left-toolbar-after>
      <span v-if="isEditing" class="op-icon-divider" />
      <button
        v-if="canEdit && !isEditing"
        class="op-button"
        aria-hidden="true"
        @click="onEdit"
      >
        {{ $t('core.misc.edit') }}
      </button>
      <button
        v-if="canEdit && isEditing"
        class="op-button"
        aria-hidden="true"
        @click="onSave"
      >
        {{ $t('core.misc.save') }}
      </button>
      <button
        v-if="canEdit && isEditing"
        class="op-button"
        aria-hidden="true"
        @click="onCancel"
      >
        {{ $t('core.misc.cancel') }}
      </button>
    </template>
  </mavon-editor>
</template>

<script>
import acl from '@/acl'
import { uploadFiles } from '@/modules/core/api/files'
import { parseError } from '@/utils/request'

export default {
  props: {
    messageKey: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      canEdit: acl.isSuperuser(),
      isEditing: false,
      content: ''
    }
  },
  computed: {
    mavenProps () {
      const canEdit = this.canEdit
      const isEditing = canEdit && this.isEditing
      const toolbars = {
        bold: isEditing,
        italic: isEditing,
        header: isEditing,
        underline: isEditing,
        strikethrough: isEditing,
        mark: isEditing,
        quote: isEditing,
        ol: isEditing,
        ul: isEditing,
        link: isEditing,
        imagelink: isEditing,
        table: isEditing,
        undo: isEditing,
        redo: isEditing,
        preview: isEditing,
        help: false,
        navigation: true,
        fullscreen: true,
        superscript: false,
        subscript: false,
        readmodel: false,
        htmlcode: false,
        trash: false,
        save: false,
        alignleft: false,
        aligncenter: false,
        alignright: false,
        subfield: false,
        code: false
      }
      return {
        language: 'ru',
        html: true,
        xssOptions: {
          whiteList: {
            sub: [],
            sup: [],
            u: [],
            img: ['src', 'style'],
            a: ['href', 'data-reference-type', 'data-reference'],
            figure: ['id'],
            figcaption: []
          }
        },
        previewBackground: '#fff',
        editorBackground: '#fff',
        toolbarsBackground: '#fff',
        boxShadow: false,
        tabSize: 2,
        fontSize: '15px',
        shortCut: true,
        ishljs: false,
        toolbarsFlag: true,
        toolbars,
        editable: isEditing,
        subfield: isEditing,
        defaultOpen: isEditing ? 'edit' : 'preview'
      }
    },
    messageByNames () {
      return this.$store.getters['help/messageByNames']
    }
  },
  watch: {
    messageKey: {
      handler () {
        this.stopEditing()
      },
      immediate: true
    },
    isEditing: 'setIsBusy'
  },
  methods: {
    onEdit () {
      if (this.canEdit) {
        this.isEditing = true
        this.resetMessage()
      }
    },
    async onSave () {
      try {
        await this.$store.dispatch('help/saveMessage', {
          name: this.messageKey,
          content: this.content
        })
        await this.$store.dispatch('help/fetchMessages')
        this.stopEditing()
      } catch (e) {
        this.$notify.error({
          title: this.$t('core.help.saveMessageOperation'),
          message: parseError(e)
        })
      }
    },
    onCancel () {
      this.stopEditing()
    },
    async onImgAdd (pos, file) {
      try {
        const formData = new FormData()
        formData.append('files', file)
        const resp = await uploadFiles(formData)
        const fileUrl = resp.data[0]
        this.$refs.mavon.$img2Url(pos, fileUrl)
      } catch (e) {
        this.$notify.error({
          title: this.$t('core.help.errorDuringImageUpload'),
          message: parseError(e)
        })
        this.$refs.mavon.$refs.toolbar_left.$imgDelByFilename(file.name)
      }
    },
    setIsBusy (isBusy) {
      this.$store.commit('help/SET_IS_BUSY', isBusy)
    },
    stopEditing () {
      this.isEditing = false
      this.resetMessage()
    },
    resetMessage () {
      this.content = this.messageByNames[this.messageKey] || ''
    }
  }
}
</script>

<style lang="scss">
.mavon-editor {
  &.v-note-wrapper .v-note-panel .v-note-navigation-wrapper {
    min-width: 320px;
    width: 30%;
    .v-note-navigation-content {
      overflow: auto;
      h1, h2, h3, h4, h5, h6 {
        white-space: normal;
        color: var(--color-primary-700);
        text-decoration: none;
        &:hover {
          text-decoration: none;
          color: var(--color-primary-500);
        }
      }
    }
  }
  &.markdown-body {
    h1, h2, h3, h4, h5, h6 {
      &:first-child {
        margin-top: 0;
      }
    }
    a {
      color: var(--color-primary-700);
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: var(--color-primary-500);
      }
    }
  }
}
</style>
