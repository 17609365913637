import * as api from '@/modules/core/api/feedingPlans'

export default {
  namespaced: true,

  state: {
    all: [],
    isLoading: false
  },

  getters: {
    feedingPlanByIds: state => {
      return state.all.reduce((acc, feedingPlan) => {
        acc[feedingPlan.id] = feedingPlan
        return acc
      }, {})
    },
    names: state => {
      return state.all.reduce((acc, feedingPlan) => {
        acc[feedingPlan.id] = feedingPlan.name
        return acc
      }, {})
    }
  },

  mutations: {
    SET_LOADING_STATUS: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_ALL: (state, feedingPlans) => {
      state.all = Object.freeze(feedingPlans)
    }
  },

  actions: {
    async fetchFeedingPlans ({ commit }) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.fetchFeedingPlans()
        const feedingPlans = response.data
        commit('SET_ALL', feedingPlans)
        return feedingPlans
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async createFeedingPlan ({ commit, dispatch }, data) {
      try {
        commit('SET_LOADING_STATUS', true)
        const response = await api.createFeedingPlan(data)
        const feedingPlanId = response.data
        await dispatch('fetchFeedingPlans')
        return feedingPlanId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async updateFeedingPlanById ({ commit, dispatch }, { data, id }) {
      try {
        commit('SET_LOADING_STATUS', true)
        await api.updateFeedingPlanById(id, data)
        await dispatch('fetchFeedingPlans')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    },
    async deleteFeedingPlanById ({ state, commit, dispatch }, id) {
      try {
        commit('SET_LOADING_STATUS', true)
        const plan = state.all.find((plan) => plan.id === id)
        await api.deleteFeedingPlanById(id)
        await dispatch('fetchFeedingPlans')
        if (plan?.copyOf) {
          commit('core/systemFeedingPlans/DELETE_COPY_OF', plan.copyOf, {
            root: true
          })
        }
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING_STATUS', false)
      }
    }
  }
}
