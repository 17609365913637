import request from '@/utils/request'

const PREFIX = '/api/archive'

export function fetchArchiveContent () {
  return request({
    url: `${PREFIX}/content`,
    method: 'get'
  })
}

export function archiveTankById (tankId) {
  return request({
    url: `${PREFIX}/tank/${tankId}`,
    method: 'post'
  })
}

export function restoreArchivedTank (tankId, payload) {
  return request({
    url: `${PREFIX}/tank/${tankId}/restore`,
    method: 'post',
    data: payload
  })
}

export function archiveTanks (tanksIds) {
  return request({
    url: `${PREFIX}/tanks`,
    method: 'post',
    data: { tanksIds }
  })
}
