import Vue from 'vue'
import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import ru from './locales/ru'

export const i18n = i18next.createInstance()

i18n.init({
  lng: 'ru',
  fallbackLng: 'ru',
  interpolation: {
    escapeValue: false
  },
  resources: {
    ru: {
      translation: ru
    }
  },
  keySeparator: false,
  nsSeparator: '::',
  pluralSeparator: '_',
  contextSeparator: '_'
})

Vue.use(I18NextVue, { i18next: i18n })

export function setEntities (entities) {
  Object.keys(entities).forEach(key => {
    ru[`entity.${key}`] = entities[key]
  })
}
