import request from '@/utils/request'

const PREFIX = '/api/core/medicines'

export function fetchMedicines () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function createMedicine (data) {
  return request({
    url: PREFIX,
    method: 'post',
    data
  })
}

export function updateMedicineById (id, data) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    data
  })
}

export function deleteMedicineById (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}
