import request from '@/utils/request'

const PREFIX = '/api/core/notifications'

export function fetchUserNotificationsPreferences () {
  return request({
    url: `${PREFIX}/my/preferences`,
    method: 'get'
  })
}

export function updateUserNotificationsPreferences (data) {
  return request({
    url: `${PREFIX}/my/preferences`,
    method: 'put',
    data
  })
}

export function fetchUserNotificationsCounter () {
  return request({
    url: `${PREFIX}/my/counter`,
    method: 'get'
  })
}

export function fetchNotificationsByFilter (filterParams) {
  return request({
    url: `${PREFIX}/filter`,
    method: 'post',
    data: filterParams
  })
}

export function markNotificationsAsRead (notificationIds) {
  return request({
    url: `${PREFIX}/read`,
    method: 'post',
    data: notificationIds
  })
}

export function markNotificationAsRead (notificationId) {
  return request({
    url: `${PREFIX}/${notificationId}/read`,
    method: 'post'
  })
}
