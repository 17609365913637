<template>
  <div
    :class="[
      'splash-card-container',
      coverToolbar && 'hide-toolbar',
      coverNavbar && 'hide-navbar'
    ]"
  >
    <el-card class="splash-card">
      <div slot="header" class="title">
        {{ header }}
      </div>
      <div :class="['label', animated && 'animated', error && 'has-error']">
        <h1>{{ label }}</h1>
      </div>
      <div v-if="message" class="message">
        {{ message }}
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    animated: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    coverToolbar: {
      type: Boolean,
      default: false
    },
    coverNavbar: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.splash-card-container {
  position: absolute;
  top: var(--toolbar-height);
  right: 0;
  bottom: 0;
  left: var(--navbar-width);
  font-family: sans-serif;
  background-color: hsl(0, 0%, 96%);

  &.hide-toolbar {
    top: 0;
  }
  &.hide-navbar {
    left: 0;
  }

  .splash-card {
    text-align: center;
    font-size: 16px;

    .title {
      font-size: 1.5em;
      color: #555;
    }

    .label {
      user-select: none;
      color: var(--color-primary-500);
      &.has-error {
        filter: hue-rotate(180deg);
      }
      &.animated {
        color: var(--color-primary-transparent-300);
        animation: animate 2.5s linear infinite;
        background-image: url('/img/water-fish.png');
        background-repeat: repeat-x;
        background-clip: text;
      }
      h1 {
        margin: 0 0 20px 0;
        padding: 0;
        font-size: 4em;
      }
    }
    @keyframes animate {
      0%{
        background-position: left 10px top -5px;
      }
      20%{
        background-position: left 30px top 10px;
      }
      40%{
        background-position: left 50px top 15px;
      }
      60%{
        background-position: left 70px top 20px;
      }
      80%{
        background-position: left 90px top 15px;
      }
      100%{
        background-position: left 110px top -5px;
      }
    }

    .message {
      font-size: 1.2em;
      color: #555;
    }

    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 14em;
    width: 50ch;
    padding: 0 0 25px 0;
  }
}
</style>
