import request from '@/utils/request'

const PREFIX = '/api/core/system-feed-producers'

export function fetchSystemFeedProducers () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function createSystemFeedProducer (data) {
  return request({
    url: PREFIX,
    method: 'post',
    data
  })
}

export function updateSystemFeedProducerById (id, data) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    data
  })
}

export function deleteSystemFeedProducerById (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}

export function copySystemFeedProducerById (id) {
  return request({
    url: `${PREFIX}/${id}/copy-to-local`,
    method: 'get'
  })
}

export function fetchSystemFeedHandbooksMentionInFeedingPlans (id) {
  return request({
    url: `${PREFIX}/${id}/handbooks-mention/feeding-plans`,
    method: 'get'
  })
}
