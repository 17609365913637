import request from '@/utils/request'

const PREFIX = '/api/supervisor/temperature-map'

export function fetchTemperatureMapsInYear (year) {
  return request({
    url: PREFIX,
    method: 'get',
    params: { year }
  })
}

export function fetchTemperatureMapsInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: PREFIX,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function saveTemperatureMaps (maps) {
  return request({
    url: `${PREFIX}`,
    method: 'post',
    data: maps
  })
}
