import request from '@/utils/request'

// add data-click-analytics="true" on dom element
// to enable event making
// you can add some data to event payload
// data-click-field-a="value"
// data-click-field-b="value"
export function collectClickAnalytics (e) {
  e.composedPath().forEach(el => {
    if (el.dataset?.clickAnalytics === 'true') {
      const payload = {}
      Object
        .keys(el.dataset)
        .filter(key => key.startsWith('click'))
        .forEach(key => {
          if (key !== 'clickAnalytics') {
            payload[key] = el.dataset[key]
          }
        })
      saveAnalyticsEvent({
        timestamp: new Date().toISOString(),
        event: 'click',
        payload
      })
    }
  })
}

export async function saveAnalyticsEvent (data) {
  await request({
    url: '/api/core/analytics/events',
    method: 'post',
    data
  })
}
