import Vue from 'vue'
import Vuex from 'vuex'
import preferencesStore from '@/modules/core/stores/preferences'
import wizardStore from '@/modules/core/stores/wizard'
import appStore from '@/modules/core/stores/app'
import helpStore from '@/modules/core/stores/help'
import factoryStore from '@/modules/core/stores/factory'
import factoryMetricsStore from '@/modules/core/stores/factoryMetrics'
import factoryTanksStore from '@/modules/core/stores/factoryTanks'
import factorySitesStore from '@/modules/core/stores/factorySites'
import factoryVirtualSitesStore from '@/modules/core/stores/factoryVirtualSites'
import factoryEditorStore from '@/modules/core/stores/factoryEditor'
import authStore from '@/modules/core/stores/auth'
import momentMetricsStore from '@/modules/core/stores/momentMetrics'
import eventsStore from '@/modules/core/stores/events'
import notificationsStore from '@/modules/core/stores/notifications'
import indicatorsStore from '@/modules/core/stores/indicators'
import feedProducersStore from '@/modules/core/stores/feedProducers'
import systemFeedProducersStore from '@/modules/core/stores/systemFeedProducers'
import factoriesStore from '@/modules/core/stores/factories'
import factoryMapStore from '@/modules/core/stores/factoryMap'
import medicinesStore from '@/modules/core/stores/medicines'
import commonReasonsStore from '@/modules/core/stores/commonReasons'
import fishKindStore from '@/modules/core/stores/fishKind'
import feedingPlansStore from '@/modules/core/stores/feedingPlans'
import systemFeedingPlansStore from '@/modules/core/stores/systemFeedingPlans'
import aclStore from '@/modules/core/stores/acl'
import stocksStore from '@/modules/stocks/stores/stocks'
import productionFactoryStore from '@/modules/supervisor/stores/productionFactory'
import productionPlansStore from '@/modules/supervisor/stores/productionPlans'
import metricsStore from '@/modules/supervisor/stores/metrics'
import temperatureMapStore from '@/modules/supervisor/stores/temperatureMap'
import densityStore from '@/modules/supervisor/stores/density'
import degreedayStore from '@/modules/supervisor/stores/degreeday'
import feedingBriefStore from '@/modules/supervisor/stores/feedingBrief'
import thermoOximeterStore from '@/modules/iot/stores/thermoOximeter'
import archiveStore from '@/modules/archive/stores/archive'
import archiveMetricsStore from '@/modules/archive/stores/metrics'

Vue.use(Vuex)

const store = new Vuex.Store()

store.registerModule('help', helpStore)
store.registerModule('preferences', preferencesStore)
store.registerModule('wizard', wizardStore)
store.registerModule('factory', factoryStore)
store.registerModule(['factory', 'metrics'], factoryMetricsStore)
store.registerModule(['factory', 'tanks'], factoryTanksStore)
store.registerModule(['factory', 'sites'], factorySitesStore)
store.registerModule(['factory', 'virtualSites'], factoryVirtualSitesStore)
store.registerModule(['factory', 'editor'], factoryEditorStore)
store.registerModule(['factory', 'map'], factoryMapStore)
store.registerModule('momentMetrics', momentMetricsStore)
store.registerModule('core', { namespaced: true })
store.registerModule(['core', 'app'], appStore)
store.registerModule(['core', 'events'], eventsStore)
store.registerModule(['core', 'notifications'], notificationsStore)
store.registerModule(['core', 'indicators'], indicatorsStore)
store.registerModule(['core', 'feedProducers'], feedProducersStore)
store.registerModule(['core', 'systemFeedProducers'], systemFeedProducersStore)
store.registerModule(['core', 'factories'], factoriesStore)
store.registerModule(['core', 'medicines'], medicinesStore)
store.registerModule(['core', 'commonReasons'], commonReasonsStore)
store.registerModule(['core', 'fishKinds'], fishKindStore)
store.registerModule(['core', 'feedingPlans'], feedingPlansStore)
store.registerModule(['core', 'systemFeedingPlans'], systemFeedingPlansStore)
store.registerModule('auth', authStore)
store.registerModule('acl', aclStore)
store.registerModule('stocks', stocksStore)
store.registerModule('supervisor', { namespaced: true })
store.registerModule(['supervisor', 'productionFactory'], productionFactoryStore)
store.registerModule(['supervisor', 'productionPlans'], productionPlansStore)
store.registerModule(['supervisor', 'metrics'], metricsStore)
store.registerModule(['supervisor', 'temperatureMap'], temperatureMapStore)
store.registerModule(['supervisor', 'density'], densityStore)
store.registerModule(['supervisor', 'degreeday'], degreedayStore)
store.registerModule(['supervisor', 'feedingBrief'], feedingBriefStore)
store.registerModule('iot', { namespaced: true })
store.registerModule(['iot', 'thermoOximeter'], thermoOximeterStore)
store.registerModule('archive', archiveStore)
store.registerModule(['archive', 'metrics'], archiveMetricsStore)

export default store
