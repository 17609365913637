import Vue from 'vue'
import { ls, KEYS } from '@/utils/store'
import api from '@/modules/core/api/factoryMap'

export default {
  namespaced: true,
  state: {
    tanksLocation: {},
    backgroundImageId: null,
    movements: {},
    scale: ls.get(KEYS.TANKS_MAP_SCALE),
    activeTankId: null,
    isLoading: false
  },
  mutations: {
    SET_MAP_SCALE: (state, scale) => {
      ls.set(KEYS.TANKS_MAP_SCALE, scale)
      Vue.set(state, 'scale', scale)
    },
    SET_TANKS_LOCATION: (state, tanksLocation) => {
      Vue.set(state, 'tanksLocation', tanksLocation)
    },
    SET_BACKGROUND_IMAGE_ID: (state, backgroundImageId) => {
      Vue.set(state, 'backgroundImageId', backgroundImageId)
    },
    SELECT_TANK_BY_ID: (state, tankId) => {
      Vue.set(state, 'activeTankId', tankId)
    },
    DESELECT_TANK: state => {
      Vue.set(state, 'activeTankId', null)
    },
    MOVE_TANK: (state, { tankId, x, y }) => {
      Vue.set(state.movements, tankId, {
        ...state.tanksLocation[tankId],
        x,
        y
      })
    },
    RESET_MOVEMENTS: state => {
      Vue.set(state, 'movements', {})
    },
    SET_LOADING: (state, isLoading) => {
      Vue.set(state, 'isLoading', isLoading)
    }
  },
  actions: {
    async saveMap ({ commit }, map) {
      commit('SET_LOADING', true)
      try {
        await api.saveFactoryMap(map)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING', false)
      }
      commit('SET_TANKS_LOCATION', map.tanksLocation)
    }
  }
}
