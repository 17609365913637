import { minBy } from 'lodash'

export const isHandbookEmpty = function (handbook) {
  return !handbook || handbook.feeding.length === 0
}

export const isFishWeightSuitableByHandbook = function (fishWeight, handbook) {
  if (isHandbookEmpty(handbook)) {
    return false
  }
  const minFishWeight = handbook.feeding[0].fishWeight
  const maxFishWeight = handbook.feeding[handbook.feeding.length - 1].fishWeight
  return minFishWeight <= fishWeight && fishWeight <= maxFishWeight
}

export const lookupHandbookClosestFeeding = function (handbook, fishWeight) {
  return minBy(handbook.feeding, row => Math.abs(row.fishWeight - fishWeight))
}

export const lookupHandbookLeftFeeding = function (handbook, fishWeight) {
  const rows = handbook.feeding.slice()
  rows.sort((a, b) => a.fishWeight - b.fishWeight)
  const rowsCnt = rows.length
  let feeding = rows[0]
  for (let i = rowsCnt - 1; i >= 0; i--) {
    const row = rows[i]
    if (row.fishWeight <= fishWeight) {
      feeding = row
      break
    }
  }
  return feeding
}

export function getHandbookFeedingStrategy (name) {
  switch (name) {
    case 'left':
      return lookupHandbookLeftFeeding
    case 'closest':
    default:
      return lookupHandbookClosestFeeding
  }
}

export const lookupHandbookClosestWaterTemperatureColumn = function (
  handbook,
  waterTemperature
) {
  return handbook.waterTemperatures.indexOf(
    minBy(handbook.waterTemperatures, val => Math.abs(val - waterTemperature))
  )
}

export const lookupHandbookLeftWaterTemperatureColumn = function (
  handbook,
  waterTemperature
) {
  const rows = handbook.waterTemperatures.slice()
  rows.sort((a, b) => a - b)
  const rowsCnt = rows.length
  let column = 0
  for (let i = rowsCnt - 1; i >= 0; i--) {
    const row = rows[i]
    if (row <= waterTemperature) {
      column = i
      break
    }
  }
  return column
}

export function getHandbookTemperatureStrategy (name) {
  switch (name) {
    case 'left':
      return lookupHandbookLeftWaterTemperatureColumn
    case 'closest':
    default:
      return lookupHandbookClosestWaterTemperatureColumn
  }
}

export const makeNameForFeedProducer = function (fp) {
  let title = fp.manufactor
  title += fp.model ? ` ${fp.model}` : ''
  title += fp.meta ? ` (${fp.meta})` : ''
  return title
}

export const feedProducersComparatorByName = function (a, b) {
  const manufactorDiff = a.manufactor.localeCompare(b.manufactor)
  if (manufactorDiff !== 0) {
    return manufactorDiff
  }
  const modelDiff = a.model.localeCompare(b.model)
  if (modelDiff !== 0) {
    return modelDiff
  }
  const aSize = parseFloat(a.meta)
  const bSize = parseFloat(b.meta)
  if (typeof aSize === 'number' && typeof bSize === 'number') {
    return aSize - bSize
  }
  return a.meta.localeCompare(b.meta)
}

export const selectSingleFeedingHandbook = function (getHandbooks, setHandbookId) {
  const handbooks = getHandbooks()
  if (handbooks.length === 1) {
    setHandbookId(handbooks[0].id)
  }
}
