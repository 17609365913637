export function verifyFishMetricsAreValid (props) {
  if (!props) {
    return false
  }
  const { fishAmount, fishBiomass, fishWeight } = props
  return (
    fishAmount > 0 && fishBiomass > 0 && fishWeight > 0 ||
    fishAmount === 0 && fishBiomass === 0 && (
      fishWeight === 0 || fishWeight === null
    )
  )
}
