import Layout from '@/modules/core/components/Layout'
import IotDevices from '@/modules/iot/components/IotDevices'

export const makeRoutes = ({ acl, i18n }) => {
  return [
    {
      path: '/iot',
      redirect: '/iot/devices',
      component: Layout,
      meta: {
        module: 'iot',
        order: 11,
        navbar: true,
        aclChecker: () => acl.isAllowedPattern(
          acl.P.R,
          new RegExp(`^${acl.R.Iot}.*$`)
        ),
        getTitle: () => i18n.t('iot.router.home'),
        icon: 'radio-tower'
      },
      children: [
        {
          path: '',
          redirect: '/iot/devices',
          component: IotDevices,
          meta: {
            module: 'iot'
          },
          children: [
            {
              path: 'devices',
              name: 'iot.devices',
              component: () => import('@/modules/iot/pages/Iot.vue'),
              meta: {
                scope: 'iot',
                module: 'iot',
                getTitle: () => i18n.t('iot.router.thermooximeters'),
                icon: 'radio-tower',
                aclChecker: () => acl.isAllowedPattern(
                  acl.P.R,
                  new RegExp(`^${acl.R.Iot}.*$`)
                )
              }
            },
            {
              path: 'thermooximeter/:id',
              component: () => import('@/modules/iot/pages/ThermoOximeter.vue'),
              name: 'iot.thermooximeter',
              meta: {
                scope: 'iot',
                module: 'iot',
                getTitle: () => i18n.t('iot.router.thermooximeter'),
                icon: 'radio-tower',
                aclChecker: route => acl.isAllowed(
                  acl.P.R,
                  acl.R.c(acl.R.Iot, acl.R.Thermooximeter(route.params.id))
                )
              }
            }
          ]
        }
      ]
    },
    {
      path: '/iot/tables',
      name: 'iot.publicThermooximetersIndicators',
      meta: {
        scope: 'iot',
        module: 'iot',
        getTitle: () => i18n.t('iot.router.thermooximeters'),
        icon: 'clipboard-list-outline',
        public: true
      },
      component: () => import('@/modules/iot/pages/ThermoOximeterAllIndicators')
    }
  ]
}
