import request from '@/utils/request'

export function fetchHelpMessages () {
  return request({
    url: '/api/core/help/messages',
    method: 'get'
  })
}

export function saveHelpMessage (data) {
  return request({
    url: '/api/core/help/messages',
    method: 'post',
    data
  })
}

export function saveDocuments (data) {
  return request({
    url: '/api/core/help/documents',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}

export function exportHelp () {
  return request({
    url: '/api/core/help/export',
    method: 'get',
    responseType: 'blob'
  })
}

export function importHelp (data) {
  return request({
    url: '/api/core/help/import',
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data
  })
}
