import Layout from '@/modules/core/components/Layout'

export const makeRoutes = ({ acl, i18n }) => {
  return [
    {
      path: '/stocks',
      component: Layout,
      meta: {
        module: 'stocks',
        getTitle: () => i18n.t('stocks.router.stocks'),
        icon: 'garage'
      },
      children: [
        {
          path: '',
          name: 'stocks',
          component: () => import('@/modules/stocks/pages/Stocks.vue'),
          meta: {
            scope: 'stocks',
            module: 'stocks',
            getTitle: () => i18n.t('stocks.router.stocks'),
            icon: 'garage',
            order: 4,
            navbar: true,
            aclChecker: () => acl.isAllowedPattern(
              acl.P.R,
              new RegExp(`^${acl.R.Stocks}.*$`)
            )
          }
        }
      ]
    }
  ]
}
