import Vue from 'vue'
import * as api from '@/modules/supervisor/api/density'

export default {
  namespaced: true,
  state: {
    density: []
  },
  getters: {
    getBySiteIdAndYear: state => (siteId, year) => {
      const v = state.density.find(v => v.siteId === siteId && v.year === year)
      return v?.density
    }
  },
  mutations: {
    FETCH_DENSITY: (state, density) => {
      const makeItemKey = item => `${item.siteId}-${item.year}`
      const itemKeys = density.map(makeItemKey)
      const newValue = state.density.filter(item => {
        return !itemKeys.includes(makeItemKey(item))
      }).concat(density)
      Vue.set(state, 'density', newValue)
    }
  },
  actions: {
    async fetchDensityInYear ({ commit }, year) {
      try {
        var response = await api.fetchDensityInYear(year)
      } catch (error) {
        return Promise.reject(error)
      }
      commit('FETCH_DENSITY', response.data)
    },
    async fetchDensityInPeriod ({ commit }, period) {
      try {
        var response = await api.fetchDensityInPeriod(period)
      } catch (error) {
        return Promise.reject(error)
      }
      commit('FETCH_DENSITY', response.data)
    }
  }
}
