<template>
  <div class="menu__user">
    <el-dropdown trigger="hover" @command="handleCommand">
      <div class="menu__user__info">
        <span class="__name">
          {{ userName }}
        </span>
        <el-badge
          v-if="userNotificationsCounter.dangerNotificationsCounter"
          :value="userNotificationsCounter.dangerNotificationsCounter"
          type="danger"
          class="notification-badge"
        />
        <el-badge
          v-if="userNotificationsCounter.warningNotificationsCounter"
          :value="userNotificationsCounter.warningNotificationsCounter"
          type="warning"
          class="notification-badge"
        />
        <el-badge
          v-if="userNotificationsCounter.infoNotificationsCounter"
          :value="userNotificationsCounter.infoNotificationsCounter"
          type="success"
          class="notification-badge"
        />
        <i class="el-icon-arrow-down __icon" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="goToNotifications">
          {{ $t('core.user.notifications') }}
        </el-dropdown-item>
        <el-dropdown-item command="logout" divided>
          {{ $t('core.auth.logout') }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { parseError } from '@/utils/request'
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Badge
} from 'element-ui'

export default {
  name: 'ToolbarUserMenu',

  components: {
    ElDropdown: Dropdown,
    ElDropdownMenu: DropdownMenu,
    ElDropdownItem: DropdownItem,
    ElBadge: Badge
  },
  computed: {
    userName () {
      return this.$store.state.auth.me?.name || 'Unknown user'
    },
    userNotificationsCounter () {
      return this.$store.state.core.notifications.userCounter
    }
  },
  methods: {
    handleCommand (command) {
      this[command]()
    },
    goToNotifications () {
      if (this.$route.path !== '/notifications') {
        this.$router.push('/notifications')
      }
    },
    async logout () {
      try {
        await this.$store.dispatch('auth/logOut')
      } catch (error) {
        this.$notify.error({
          title: this.$t('core.auth.logout'),
          message: parseError(error)
        })
      } finally {
        this.$router.push({ path: '/login' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.menu__user {
  display: inline-flex;
  height: 100%;

  .notification-badge:first-of-type {
    margin-left: 10px;
  }
  .notification-badge + .notification-badge {
    margin-left: 6px;
  }

  .menu__user__info {
    box-sizing: border-box;
    height: var(--toolbar-height);
    display: inline-flex;
    align-items: center;
    padding: 0 20px 0;
    background-color: var(--color-primary-600);
    overflow: hidden;
    cursor: pointer;

    letter-spacing: 0px;
    color: rgb(255, 255, 255);
    font-size: 18px;

    .__name {
      font-weight: 700;
    }
    .__icon {
      margin-left: 20px;
    }
  }
}
</style>
