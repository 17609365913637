import request from '@/utils/request'

const PREFIX = '/api/core/events'

export function fetchEventsInPeriod ({ periodStart, periodEnd }) {
  return request({
    url: `${PREFIX}`,
    method: 'get',
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchEventsByFilter (filterParams) {
  return request({
    url: `${PREFIX}/filter`,
    method: 'post',
    data: filterParams
  })
}
