export const getPlansInPeriodByTankId = ({
  plans,
  periodStart,
  periodEnd,
  tankId
}) => {
  const start = new Date(periodStart).getTime()
  const end = new Date(periodEnd).getTime()
  return plans.filter(plan => {
    if (plan.tankId !== tankId) {
      return false
    }
    if (Date.parse(plan.seasonStart) > end) {
      return false
    }
    if (start > Date.parse(plan.seasonEnd)) {
      return false
    }
    return true
  })
}

export const getPlanByDate = (plans, date) => {
  const dateInISO8601 = new Date(date).toISOString()
  return plans.find(plan => (
    plan.seasonStart <= dateInISO8601 && dateInISO8601 <= plan.seasonEnd
  ))
}

export const getPlansByTankId = (plans, tankId) => {
  return plans.filter(plan => plan.tankId === tankId)
}

export const getDateBoundariesByPlans = plans => {
  if (plans.length === 0) {
    return null
  }
  const period = plans.reduce((period, plan) => {
    const startDate = new Date(plan.seasonStart)
    if (startDate < period[0]) {
      period[0] = startDate
    }
    const endDate = new Date(plan.seasonEnd)
    if (period[1] < endDate) {
      period[1] = endDate
    }
    return period
  }, [new Date(plans[0].seasonStart), new Date(plans[0].seasonEnd)])
  return period.map(date => date.toISOString())
}

export const getPlanYears = plan => {
  const startYear = new Date(plan.seasonStart).getFullYear()
  const endYear = new Date(plan.seasonEnd).getFullYear()
  const years = []
  for (let i = startYear; i <= endYear; i++) {
    years.push(i)
  }
  return years
}
