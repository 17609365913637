import { fetchArchiveContent } from '@/modules/archive/api/archive'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    activeTankId: null,
    content: []
  },

  getters: {
    activeSite: (state, getters) => {
      return getters.activeTank && state.content.find(record => record.id === getters.activeTank.siteId) || null
    },
    activeTank: (state, getters) => {
      return getters.tanks.find(tank => tank.id === state.activeTankId) || null
    },
    tanks: (state) => {
      return state.content.flatMap(siteRecord => siteRecord.tanks)
    },
    tanksLocations: (state) => {
      return state.content.reduce((locations, site) => {
        site.tanks.forEach(tank => {
          locations[tank.id] = `${site.displayName} / ${tank.displayName}`
        })
        return locations
      }, {})
    }
  },

  mutations: {
    SET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_CONTENT: (state, content) => {
      state.content = content
    },
    SET_ACTIVE_TANK_ID: (state, activeTankId) => {
      state.activeTankId = activeTankId
    }
  },

  actions: {
    async fetchContent ({ commit }) {
      commit('SET_LOADING', true)
      try {
        const response = await fetchArchiveContent()
        commit('SET_CONTENT', response.data)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async selectTank ({ commit, dispatch }, tankId) {
      commit('SET_ACTIVE_TANK_ID', tankId)
      await dispatch('archive/metrics/fetchByTank', tankId, { root: true })
    }
  }
}
