import acl from '@/acl'
import { fetchFactoryContent } from '@/modules/core/api/factories'
import {
  getFactoryContentForUser,
  sortFactory
} from '@/modules/core/use/factory'
import { makeTanksActivityMap } from '@/modules/core/use/virtualSite'
import { havePeriodsHitDate } from '@/utils/date'

export default {
  namespaced: true,

  state: {
    isLoading: false,
    allContent: null,
    content: null
  },

  mutations: {
    SET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    },
    SET_FACTORY_ALL_CONTENT: (state, content) => {
      state.allContent = content
    },
    SET_FACTORY_CONTENT: (state, content) => {
      state.content = content
    }
  },

  actions: {
    async fetchFactoryContent ({ commit, dispatch }) {
      commit('SET_LOADING', true)
      try {
        const { data: allContent } = await fetchFactoryContent()
        sortFactory(allContent)
        const date = new Date()
        allContent.virtualSites.forEach(site => {
          site.activityMap = makeTanksActivityMap(site)
          site.curTankIds = site.tankIds.filter(tankId => {
            const periods = site.activityMap[tankId]
            return periods.length === 0 || havePeriodsHitDate(periods, date)
          })
        })
        commit('SET_FACTORY_ALL_CONTENT', allContent)
        const userContent = getFactoryContentForUser(allContent, acl)
        commit('SET_FACTORY_CONTENT', userContent)
        commit('factory/map/SET_TANKS_LOCATION', allContent.map.tanksLocation, { root: true })
        commit('factory/map/SET_BACKGROUND_IMAGE_ID', allContent.map.backgroundImageId, { root: true })
        commit('factory/map/RESET_MOVEMENTS', null, { root: true })
      } catch (error) {
        commit('SET_LOADING', false)
        return Promise.reject(error)
      }
      if (acl.isAllowed(acl.P.R, acl.R.Archive)) {
        try {
          await dispatch('archive/fetchContent', null, { root: true })
        } catch (e) {
          console.error(e)
        }
      }
      commit('SET_LOADING', false)
    }
  }
}
