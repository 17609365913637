import request from '@/utils/request'

const PREFIX = '/api/core/auth'

export function fetchAuthLog () {
  return request({
    url: `${PREFIX}/log`,
    method: 'get'
  })
}

export function loginByUsername (data) {
  return request({
    url: `${PREFIX}/login`,
    method: 'post',
    data
  })
}

export function logout () {
  return request({
    url: `${PREFIX}/logout`,
    method: 'get'
  })
}

export function getInfoByMe () {
  return request({
    url: `${PREFIX}/info`,
    method: 'get'
  })
}
