import {
  fetchFishKinds,
  createFishKind,
  updateFishKindById,
  deleteFishKindById
} from '@/modules/core/api/fishKind'

export default {
  namespaced: true,
  state: {
    all: [],
    isLoading: false
  },
  getters: {
    names: state => {
      return state.all.reduce((names, fishKind) => {
        names[fishKind.id] = fishKind.name
        return names
      }, {})
    },
    byIds: state => {
      return state.all.reduce((acc, fishKind) => {
        acc[fishKind.id] = fishKind
        return acc
      }, {})
    }
  },
  mutations: {
    SET_ALL: (state, all) => {
      state.all = all
    },
    SET_IS_LOADING: (state, isLoading) => {
      state.isLoading = isLoading
    }
  },
  actions: {
    async fetchFishKinds ({ commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const { data } = await fetchFishKinds()
        commit('SET_ALL', data)
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async createFishKind ({ commit, dispatch }, fishKind) {
      try {
        commit('SET_IS_LOADING', true)
        const { data: fishKindId } = await createFishKind(fishKind)
        await dispatch('fetchFishKinds')
        return fishKindId
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async updateFishKindById ({ commit, dispatch }, { id, data }) {
      try {
        commit('SET_IS_LOADING', true)
        await updateFishKindById(id, data)
        await dispatch('fetchFishKinds')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    },
    async deleteFishKindById ({ commit, dispatch }, id) {
      try {
        commit('SET_IS_LOADING', true)
        await deleteFishKindById(id)
        await dispatch('fetchFishKinds')
      } catch (error) {
        return Promise.reject(error)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    }
  }
}
