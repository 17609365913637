export default {
  namespaced: true,

  getters: {
    all: (state, getters, rootState) => {
      return rootState.factory.allContent?.virtualSites || []
    },
    forUser: (state, getters, rootState) => {
      return rootState.factory.content?.virtualSites || []
    },
    forUserWithFish: (state, getters, rootState, rootGetters) => {
      const hasSiteFish =
        site => rootGetters['factory/metrics/hasFishByVirtualSites'][site.id]
      return getters.forUser.filter(site => hasSiteFish(site))
    },
    visible: (state, getters, rootState) => {
      return rootState.core.app.isEmptySitesVisible
        ? getters.forUser
        : getters.forUserWithFish
    },
    allByIds: (state, getters) => {
      return getters.all.reduce((acc, site) => {
        acc[site.id] = site
        return acc
      }, {})
    },
    byIds: (state, getters) => {
      return getters.forUser.reduce((acc, site) => {
        acc[site.id] = site
        return acc
      }, {})
    },
    names: (state, getters) => {
      return getters.all.reduce((names, site) => {
        names[site.id] = site.name
        return names
      }, {})
    },
    colors: (state, getters) => {
      return getters.all.reduce((colors, site) => {
        colors[site.id] = site.color
        return colors
      }, {})
    }
  }
}
