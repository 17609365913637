import Vue from 'vue'
import {
  Scrollbar,
  Dialog,
  Form,
  FormItem,
  Input,
  Checkbox,
  Select,
  Option,
  Button,
  Tooltip,
  Row,
  Col,
  Card,
  Link,
  Loading,
  Popover,
  Tabs,
  TabPane,
  Notification,
  MessageBox
} from 'element-ui'

Vue.use(Scrollbar)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Option)
Vue.use(Button)
Vue.use(Tooltip)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Link)
Vue.use(Loading)
Vue.use(Popover)
Vue.use(Tabs)
Vue.use(TabPane)

Vue.use(Loading.directive)

Vue.prototype.$notify = Notification
Vue.prototype.$confirm = MessageBox.confirm
