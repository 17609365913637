import request from '@/utils/request'

const PREFIX = '/api/core/fish-kind'

export function fetchFishKinds () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function createFishKind (data) {
  return request({
    url: PREFIX,
    method: 'post',
    data
  })
}

export function updateFishKindById (id, data) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    data
  })
}

export function deleteFishKindById (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}
