import request from '@/utils/request'

const PREFIX = '/api/core/feeding-plans'

export function fetchFeedingPlans () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function createFeedingPlan (data) {
  return request({
    url: PREFIX,
    method: 'post',
    data
  })
}

export function updateFeedingPlanById (id, data) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    data
  })
}

export function deleteFeedingPlanById (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'delete'
  })
}
