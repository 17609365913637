import ru from 'date-fns/locale/ru'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import isValid from 'date-fns/isValid'
import add from 'date-fns/add'
import sub from 'date-fns/sub'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import _startOfWeek from 'date-fns/startOfWeek'
import _endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'
import differenceInDays from 'date-fns/differenceInDays'
import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import intervalToDuration from 'date-fns/intervalToDuration'
import _formatDuration from 'date-fns/formatDuration'
import { i18n } from '@/i18next'

const locales = { ru }

export const DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm'
export const DATE_KEY_FORMAT = 'yyyy-MM-dd'
export const yyyy_LLLL_FORMAT = 'yyyy LLLL'
export const d_MMM_FORMAT = 'd MMM'
export const d_MMM_yyyy_FORMAT = 'd MMM yyyy'
export const d_MMM_HH_mm_FORMAT = 'd MMM HH:mm'
export const d_MMM_HH_mm_ss_FORMAT = 'd MMM HH:mm:ss'
export const d_MMM_yyyy_HH_mm_FORMAT = 'd MMM yyyy HH:mm'

const getLocale = () => locales[i18n.language]

export const formatDate = (date, formatStr = DATE_TIME_FORMAT) => format(
  date,
  formatStr,
  {
    locale: getLocale()
  }
)
export const formatTimestamp = timestamp => formatDate(
  new Date(timestamp),
  DATE_TIME_FORMAT
)

export const formatDuration = milliseconds => {
  const duration = intervalToDuration({
    start: new Date(0),
    end: new Date(milliseconds)
  })
  return _formatDuration(duration, {
    format: [
      'years',
      'months',
      'days',
      'hours',
      'minutes'
    ],
    locale: getLocale()
  })
}

export const startOfWeek = date => _startOfWeek(date, {
  locale: getLocale()
})
export const endOfWeek = date => _endOfWeek(date, {
  locale: getLocale()
})

export const makeDateKeysBetween = (start, end) => eachDayOfInterval({
  start,
  end
}).map(date => formatDate(date, DATE_KEY_FORMAT))

export const havePeriodsHitPeriod = (periods, period) => {
  const pBegin = startOfDay(parseISO(period[0]))
  const pEnd = endOfDay(parseISO(period[1]))
  return periods.some(candidate => {
    const cBegin = candidate[0] ? startOfDay(parseISO(candidate[0])) : 0
    const cEnd = candidate[1] ? endOfDay(parseISO(candidate[1])) : Infinity
    return Math.max(pBegin, cBegin) <= Math.min(pEnd, cEnd)
  })
}

export const havePeriodsHitDate = (periods, date) => {
  const dateObj = typeof date === 'string' ? parseISO(date) : date
  return periods.some(candidate => {
    const cBegin = candidate[0] ? startOfDay(parseISO(candidate[0])) : 0
    const cEnd = candidate[1] ? endOfDay(parseISO(candidate[1])) : Infinity
    return cBegin <= dateObj && dateObj <= cEnd
  })
}

export {
  parseISO,
  isValid,
  add,
  sub,
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  differenceInDays,
  eachDayOfInterval
}
