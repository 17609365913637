export default {
  data () {
    return {
      loading: false
    }
  },

  methods: {
    turnLoading (state) {
      this.loading = state
    }
  }
}
