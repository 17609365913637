import { groupBy, chunk, intersection } from 'lodash'
import { i18n } from '@/i18next'
import { formatDate } from '@/utils/date'

export const mapTankWidth = 180

export const mapTankHeight = 180

export const generateFactoryMap = function (params) {
  const options = {
    tanks: [],
    startX: 10,
    startY: 10,
    columns: 5,
    ...params
  }
  const factoryMap = {}
  const columns = options.columns
  let y = options.startY
  const tanksBySite = groupBy(options.tanks, tank => tank.siteId)
  Object.keys(tanksBySite).forEach((siteId, siteIndex) => {
    const siteRows = chunk(tanksBySite[siteId], columns)
    siteRows.forEach((siteRow, siteRowIndex) => {
      let x = options.startX
      siteRow.forEach((tank, tankIndex) => {
        factoryMap[tank.id] = {
          x: x,
          y: y,
          siteId
        }
        x += mapTankWidth + 20
      })
      y += mapTankHeight + 20
    })
    y += 40
  })
  return factoryMap
}

export const makeTankCascaderOptions = function (factory, options) {
  const isTankDisabled = options?.isTankDisabled || (() => false)
  const comparator = options?.comparator || ((a, b) => a.disabled - b.disabled)
  const tankByIds = factory.tanks.reduce((acc, tank) => {
    acc[tank.id] = tank
    return acc
  }, {})
  const getTankOption = (id, label, siteId) => ({
    key: `site-${siteId}-tank-${id}`,
    value: id,
    label,
    disabled: isTankDisabled(id)
  })
  const getSiteOption = (id, label, tankOptions) => ({
    key: `site-${id}`,
    value: id,
    label,
    children: tankOptions,
    disabled: tankOptions.every(option => option.disabled)
  })
  const getArchivedTankLabel = (archivedTank) => i18n.t('core.tank.archivedSince', {
    name: archivedTank.name,
    archivedAt: formatDate(new Date(archivedTank.archivedAt), 'dd.MM.yyyy')
  })
  const archiveContent = options?.archiveContent || []
  const siteOptions = factory.sites.reduce((options, site) => {
    const tankOptions = site.tankIds
      .map(tankId => tankByIds[tankId])
      .filter(Boolean)
      .map(tank => getTankOption(tank.id, tank.name, site.id))
    tankOptions.sort(comparator)
    const archivedTanks = archiveContent.find(item => item.id === site.id)?.tanks || []
    for (const archivedTank of archivedTanks) {
      const label = getArchivedTankLabel(archivedTank)
      const tankOption = getTankOption(archivedTank.id, label, site.id)
      tankOptions.push(tankOption)
    }
    const siteOption = getSiteOption(site.id, site.name, tankOptions)
    options.push(siteOption)
    return options
  }, [])
  siteOptions.sort(comparator)
  for (const archivedTanksRecord of archiveContent) {
    if (!archivedTanksRecord.isDeleted) continue
    const tankOptions = archivedTanksRecord.tanks.map(archivedTank => getTankOption(
      archivedTank.id,
      getArchivedTankLabel(archivedTank),
      archivedTanksRecord.id
    ))
    const siteLabel = i18n.t('core.site.deletedSince', {
      name: archivedTanksRecord.name,
      deletedAt: formatDate(new Date(archivedTanksRecord.deletedAt), 'dd.MM.yyyy')
    })
    const siteOption = getSiteOption(archivedTanksRecord.id, siteLabel, tankOptions)
    siteOptions.push(siteOption)
  }
  const hasFactoryLevel = options?.hasFactoryLevel || false
  if (hasFactoryLevel) {
    return [{
      key: 'factory',
      value: 'factory',
      label: i18n.t('entity.factory'),
      children: siteOptions,
      disabled: siteOptions.every(option => option.disabled)
    }]
  }
  return siteOptions
}

export const getFactoryContentForUser = function (factory, acl) {
  const userFactory = {}

  const canReadTanks = {}
  factory.tanks.forEach(tank => {
    canReadTanks[tank.id] = acl.isAllowed(
      acl.P.R,
      acl.R.c(acl.R.Factory, acl.R.Tank(tank.id))
    )
  })
  userFactory.tanks = factory.tanks.filter(tank => canReadTanks[tank.id])

  userFactory.sites = factory.sites
    .map(site => ({
      ...site,
      tankIds: site.tankIds.filter(tankId => canReadTanks[tankId])
    }))
    .filter(site => site.tankIds.length ||
      acl.isAllowed(
        acl.P.R,
        acl.R.c(acl.R.Factory, acl.R.Site(site.id))
      )
    )

  userFactory.virtualSites = factory.virtualSites
    .map(site => ({
      ...site,
      tankIds: site.tankIds.filter(tankId => canReadTanks[tankId]),
      curTankIds: site.curTankIds.filter(tankId => canReadTanks[tankId])
    }))
    .filter(site => site.tankIds.length === 0 ||
      site.tankIds.some(tankId => canReadTanks[tankId])
    )

  userFactory.map = {
    ...factory.map,
    tanksLocation: {}
  }
  userFactory.tanks.forEach(tank => {
    userFactory.map.tanksLocation[tank.id] = factory.map.tanksLocation[tank.id]
  })

  return userFactory
}

export function getUnionTanksBySite (tankIds, factory) {
  const unionTanksBySite = {
    sites: [],
    tanks: [],
    partialSites: []
  }
  const siteIdByTankIds = {}
  const factoryTankIdsBySiteIds = {}
  factory.tanks.forEach(tank => {
    siteIdByTankIds[tank.id] = tank.siteId
    if (factoryTankIdsBySiteIds[tank.siteId]) {
      factoryTankIdsBySiteIds[tank.siteId].push(tank.id)
    } else {
      factoryTankIdsBySiteIds[tank.siteId] = [tank.id]
    }
  })
  const collectedTankIdsBySiteIds = tankIds.reduce((acc, tankId) => {
    const siteId = siteIdByTankIds[tankId]
    if (acc.hasOwnProperty(siteId)) {
      acc[siteId].push(tankId)
    } else {
      acc[siteId] = [tankId]
    }
    return acc
  }, {})

  for (const [siteId, collectedTankIds] of Object.entries(collectedTankIdsBySiteIds)) {
    const siteTankIds = factoryTankIdsBySiteIds[siteId] || []
    const existedSiteTankIds = intersection(collectedTankIds, siteTankIds)
    if (existedSiteTankIds.length === siteTankIds.length) {
      unionTanksBySite.sites.push(siteId)
    } else {
      unionTanksBySite.tanks.push(...collectedTankIds)
      unionTanksBySite.partialSites.push({
        siteId,
        tankIds: collectedTankIds
      })
    }
  }

  return unionTanksBySite
}

export function sortFactory (factory) {
  const tanksCount = factory.tanks.length
  const weights = factory.sites.reduce((acc, site, siteIndex) => {
    const startIndex = tanksCount * siteIndex
    site.tankIds.map((tankId, tankIndex) => {
      acc[tankId] = startIndex + tankIndex
    })
    return acc
  }, {})
  factory.tanks.sort((a, b) => weights[a.id] - weights[b.id])
}

export function getTankVolume (tank) {
  if (!tank.size) {
    return 0
  }
  if (tank.shape === 'rectangle') {
    return tank.size.length * tank.size.width * tank.size.height || 0
  }
  if (tank.shape === 'circle') {
    return Math.PI * Math.pow(tank.size.radius, 2) * tank.size.height || 0
  }
  return 0
}

export function getTankArea (tank) {
  if (!tank.size) {
    return 0
  }
  if (tank.shape === 'rectangle') {
    return tank.size.length * tank.size.width || 0
  }
  if (tank.shape === 'circle') {
    return Math.PI * Math.pow(tank.size.radius, 2) || 0
  }
  return 0
}
