import { Notification } from 'element-ui'
import { formatDate } from '@/utils/date'
import { i18n } from '@/i18next'
import store from '@/store'
import { subscribe, N } from '@/plugins/io'

let notification = null
let intervalId = null

const TIMER_DURATION = 1000 * 60 * 60 // 1 HOUR

export function initUpdateWatcher () {
  subscribe(N.APP_IS_READY, onAppIsReady)
  subscribe(N.APP_IS_UPDATING, onAppIsUpdating)
  subscribe(N.START_APP_UPDATE_AT, onStartAppUpdateAt)
}

function onAppIsReady () {
  if (store.state.core.app.isUpdating) {
    store.commit('core/app/SET_UPDATING_MESSAGE', i18n.t('core.app.updateIsDone'))
  } else {
    resetNotificationIfExist()
  }
}

function onAppIsUpdating (event) {
  const startUpdateAt = new Date(event.startUpdateAt)
  scheduleAppUpdate(startUpdateAt)
}

function onStartAppUpdateAt (event) {
  const startUpdateAt = new Date(event.startUpdateAt)
  scheduleAppUpdate(startUpdateAt)
}

function scheduleAppUpdate (startUpdateAt) {
  resetAppUpdate()
  const timeout = startUpdateAt - new Date()
  if (timeout > 0) {
    notification = Notification({
      message: getAppUpdateMessage(startUpdateAt, timeout),
      duration: 0,
      onClose: onNotificationClose,
      customClass: 'app-notification app-notification--pulse'
    })
    store.commit('core/app/SET_IS_UPDATING', false)
    store.commit('core/app/SET_UPDATING_MESSAGE', '')
  }

  intervalId = setInterval(() => {
    const timeout = startUpdateAt - new Date()
    if (timeout < 0) {
      resetNotificationIfExist()
      clearInterval(intervalId)
      store.commit('core/app/SET_IS_UPDATING', true)
      store.commit('core/app/SET_UPDATING_MESSAGE', i18n.t('core.app.runningFishwebUpdate'))
    }
    if (notification) {
      updateNotification(notification, startUpdateAt, timeout)
    }
  }, 1000)
}

function resetAppUpdate () {
  resetNotificationIfExist()
  if (intervalId) {
    clearInterval(intervalId)
  }
}

function onNotificationClose () {
  notification = null
}

function getAppUpdateMessage (startUpdateAt, timeout) {
  if (timeout > TIMER_DURATION) {
    return i18n.t('core.app.updateWillStartAt', {
      startUpdateAt: formatDate(startUpdateAt, 'dd MMMM в HH:mm')
    })
  } else if (timeout < 0) {
    return i18n.t('core.app.runningFishwebUpdate')
  } else {
    const date = new Date(timeout)
    return i18n.t('core.app.updateWillStartIn', {
      startUpdateIn: formatDate(date, ' mm:ss')
    })
  }
}

function updateNotification (notification, startUpdateAt, timeout) {
  notification.message = getAppUpdateMessage(startUpdateAt, timeout)
  notification.customClass = (timeout > 0 && timeout < TIMER_DURATION)
    ? 'app-notification app-notification--pulse'
    : 'app-notification'
}

function resetNotificationIfExist () {
  if (notification) {
    notification.close()
    notification = null
  }
}
