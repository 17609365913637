import { getField, updateField } from 'vuex-map-fields'
import { cloneDeep, uniqueId } from 'lodash'
import { i18n } from '@/i18next'

export const makeTankForm = (data = {}) => {
  return {
    id: data.id ?? null,
    __v: data.__v ?? undefined,
    key: uniqueId('tank-form-'),
    name: data.name ?? i18n.t('core.tank.single'),
    siteId: data.siteId ?? null,
    color: data.color ?? '',
    shape: data.shape ?? 'rectangle',
    size: {
      length: data.size?.length,
      width: data.size?.width,
      radius: data.size?.radius,
      height: data.size?.height
    },
    positionNumber: data.positionNumber ?? undefined,
    virtualSiteIds: data.virtualSiteIds ? data.virtualSiteIds.slice() : []
  }
}

export const makeSiteForm = (data = {}, siteTanks = []) => {
  const key = uniqueId('site-form-')
  const tanks = siteTanks.map(tank => makeTankForm(tank))
  const lastTankNumber = tanks.length
  return {
    id: data.id ?? null,
    __v: data.__v,
    lastTankNumber,
    key,
    ref: key,
    name: data.name ?? i18n.t('core.site.single'),
    color: data.color ?? null,
    tankIds: data.tankIds ?? siteTanks.map(tank => tank.id),
    tanks
  }
}

function makeVirtualSiteForm (data) {
  const form = {
    id: null,
    __v: undefined,
    name: '',
    color: '#616161',
    tankIds: [],
    tanksTimeLimits: []
  }
  if (data) {
    form.id = data.id
    form.__v = data.__v
    form.name = data.name
    form.color = data.color
    form.tankIds = data.tankIds.slice()
    form.tanksTimeLimits = cloneDeep(data.tanksTimeLimits)
  }
  return form
}

export default {
  namespaced: true,

  state: {
    tankForm: makeTankForm(),
    siteForm: makeSiteForm(),
    virtualSiteForm: makeVirtualSiteForm()
  },

  getters: {
    getField
  },

  mutations: {
    updateField,

    RESET_TANK_FORM: state => {
      state.tankForm = makeTankForm()
    },
    SET_TANK_FORM: (state, data) => {
      state.tankForm = makeTankForm(data)
    },

    RESET_SITE_FORM: state => {
      state.siteForm = makeSiteForm()
    },
    SET_SITE_FORM: (state, data) => {
      state.siteForm = makeSiteForm(data)
    },

    RESET_VIRTUAL_SITE_FORM: state => {
      state.virtualSiteForm = makeVirtualSiteForm()
    },
    SET_VIRTUAL_SITE_FORM: (state, data) => {
      state.virtualSiteForm = makeVirtualSiteForm(data)
    }
  }
}
