import { fetchUserNotificationsCounter } from '@/modules/core/api/notifications'

export default {
  namespaced: true,

  state: {
    userCounter: {
      dangerNotificationsCounter: 0,
      warningNotificationsCounter: 0,
      infoNotificationsCounter: 0
    }
  },

  mutations: {
    SET_USER_COUNTER: (state, userCounter) => {
      state.userCounter = userCounter
    }
  },

  actions: {
    async loadUserNotificationsCounter ({ commit }) {
      try {
        const { data } = await fetchUserNotificationsCounter()
        commit('SET_USER_COUNTER', {
          dangerNotificationsCounter: data.dangerNotificationsCounter,
          warningNotificationsCounter: data.warningNotificationsCounter,
          infoNotificationsCounter: data.infoNotificationsCounter
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
