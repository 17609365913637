<script>
import { InputNumber } from 'element-ui'

/**
 *
 * Обертка над element-ui input number
 *
 * можно указать null и он не конвертируется в 0
 * toFixed позволяет отключить вывод с фиксированным количеством знаков
 *
 */

export default {
  name: 'InputNumber',
  extends: InputNumber,

  props: {
    toFixed: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      currentValue: undefined
    }
  },

  computed: {
    displayValue () {
      if (this.userInput !== null) {
        return this.userInput
      }
      let currentValue = this.currentValue
      if (typeof currentValue === 'number') {
        if (this.stepStrictly) {
          const stepPrecision = this.getPrecision(this.step)
          const precisionFactor = Math.pow(10, stepPrecision)
          currentValue = Math.round(currentValue / this.step) * precisionFactor * this.step / precisionFactor
        }
        if (this.precision !== undefined && this.toFixed) {
          currentValue = currentValue.toFixed(this.precision)
        }
      }
      return currentValue
    }
  },

  watch: {
    value: {
      immediate: true,
      handler (value) {
        let newVal = value == null ? value : Number(value)
        if (newVal != null) {
          if (isNaN(newVal)) {
            return
          }
          if (this.stepStrictly) {
            const stepPrecision = this.getPrecision(this.step)
            const precisionFactor = Math.pow(10, stepPrecision)
            newVal = Math.round(newVal / this.step) * precisionFactor * this.step / precisionFactor
          }
          if (this.precision !== undefined) {
            newVal = this.toPrecision(newVal, this.precision)
          }
        }
        if (typeof newVal === 'number') {
          if (newVal >= this.max) newVal = this.max
          if (newVal <= this.min) newVal = this.min
        }
        this.currentValue = newVal
        this.userInput = null
        this.$emit('input', newVal)
      }
    }
  }
}
</script>
