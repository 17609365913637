import Vue from 'vue'
import '@/i18next'
import store from '@/store'
import router from '@/router'
import '@/plugins/ui-kit'
import '@/plugins/element'
import '@/plugins/markdown'
import App from '@/App.vue'
import { initUpdateWatcher } from '@/utils/appUpdateHelper'

Vue.config.productionTip = false

import '@mdi/font/css/materialdesignicons.css'
import '@/styles/main.scss'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

initUpdateWatcher()
