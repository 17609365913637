import Layout from '@/modules/core/components/Layout'

export const makeRoutes = ({ acl, i18n }) => {
  return [
    {
      path: '/supervisor',
      component: Layout,
      redirect: { name: 'productionPlans' },
      meta: {
        module: 'supervisor',
        getTitle: () => i18n.t('supervisor.router.supervisor'),
        icon: 'account-tie',
        order: 9,
        navbar: true,
        aclChecker: () => acl.isAllowedPattern(
          acl.P.R,
          new RegExp(`^${acl.R.Supervisor}.*$`)
        )
      },
      children: [
        {
          path: 'plans',
          name: 'productionPlans',
          component: () => import('@/modules/supervisor/pages/ProductionPlans.vue'),
          meta: {
            scope: 'productionPlans',
            module: 'supervisor',
            getTitle: () => i18n.t('supervisor.router.productionPlans'),
            icon: 'account-tie'
          }
        }
      ]
    }
  ]
}
