import request from '@/utils/request'

const PREFIX = '/api/iot/thermooximeter'

export function get (id) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'get'
  })
}

export function update ({ id, data }) {
  return request({
    url: `${PREFIX}/${id}`,
    method: 'put',
    data
  })
}

export function fetchAll () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function getAllIndicators (limit) {
  return request({
    url: `${PREFIX}/indicator`,
    method: 'get',
    params: {
      limit
    }
  })
}
export function getHistoryInPeriod ({ id, params }) {
  return request({
    url: `${PREFIX}/${id}/history/filter`,
    method: 'post',
    data: params
  })
}
export function getIndicatorsInPeriod ({ id, params }) {
  return request({
    url: `${PREFIX}/${id}/indicators`,
    method: 'get',
    params
  })
}
export function getWorkRules ({ id }) {
  return request({
    url: `${PREFIX}/${id}/workrule`,
    method: 'get'
  })
}
export function createWorkRule ({ id, data }) {
  return request({
    url: `${PREFIX}/${id}/workrule`,
    method: 'post',
    data
  })
}
export function updateWorkRule ({ id, workRuleId, data }) {
  return request({
    url: `${PREFIX}/${id}/workrule/${workRuleId}`,
    method: 'put',
    data
  })
}
export function removeWorkRule ({ id, workRuleId }) {
  return request({
    url: `${PREFIX}/${id}/workrule/${workRuleId}`,
    method: 'delete'
  })
}
