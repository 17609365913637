const config = {
  debug: {
    request: true
  },
  VERSION: process.env.APP_VERSION,
  API_REQUEST_TIMEOUT: 0,
  SHOW_MSG_TIMEOUT: 3000
}

export default config
