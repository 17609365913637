import request from '@/utils/request'

const PREFIX = '/api/core/factories'

export function fetchFactories () {
  return request({
    url: PREFIX,
    method: 'get'
  })
}

export function fetchFactoryContent () {
  return request({
    url: `${PREFIX}/content`,
    method: 'get'
  })
}

export function addFactoryContent (data) {
  return request({
    url: `${PREFIX}/content`,
    method: 'post',
    data
  })
}

export function fetchFactoryById (factoryId) {
  return request({
    url: `${PREFIX}/${factoryId}`,
    method: 'get'
  })
}

export function addFactory (factory) {
  return request({
    url: PREFIX,
    method: 'post',
    data: factory
  })
}

export function updateFactory (params) {
  return request({
    url: `${PREFIX}/${params.id}`,
    method: 'put',
    data: params
  })
}

export function deleteFactoryById (factoryId) {
  return request({
    url: `${PREFIX}/${factoryId}`,
    method: 'delete'
  })
}

export function fetchUserInfo (factoryServer) {
  return request({
    url: `${factoryServer}/api/core/auth/info`,
    method: 'get',
    withCredentials: true
  })
}

export function loginIntoFactory (data, factoryServer) {
  return request({
    url: `${factoryServer}/api/core/auth/login`,
    method: 'post',
    withCredentials: true,
    data
  })
}

export function fetchFactoryBrief (factoryServer, options = {}) {
  return request({
    url: `${factoryServer}/api/core/factory/brief`,
    method: 'get',
    withCredentials: true,
    params: options
  })
}

export function fetchFactoryFishChangesInPeriod (factoryServer, {
  periodStart,
  periodEnd
}) {
  return request({
    url: `${factoryServer}/api/core/indicators/fish/change`,
    method: 'get',
    withCredentials: true,
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchFactoryWaterTemperatureMapInPeriod (factoryServer, {
  periodStart,
  periodEnd
}) {
  return request({
    url: `${factoryServer}/api/core/indicators/water/temperature/map`,
    method: 'get',
    withCredentials: true,
    params: {
      start: periodStart,
      end: periodEnd
    }
  })
}

export function fetchFactoryTanks (factoryServer) {
  return request({
    url: `${factoryServer}/api/core/tanks`,
    method: 'get',
    params: {
      skipSummary: true
    },
    withCredentials: true
  })
}

export function fetchFactoryProductionPlansInPeriod (factoryServer, period) {
  return request({
    url: `${factoryServer}/api/supervisor/production-plans/filter`,
    method: 'post',
    data: {
      start: period.periodStart,
      end: period.periodEnd
    },
    withCredentials: true
  })
}

export function fetchFactoryFeedProducers (factoryServer) {
  return request({
    url: `${factoryServer}/api/core/feed-producers`,
    method: 'get',
    withCredentials: true
  })
}

export function fetchFactoryFeedingPlans (factoryServer) {
  return request({
    url: `${factoryServer}/api/core/feeding-plans`,
    method: 'get',
    withCredentials: true
  })
}

export function fetchFactoryTemperatureMapsInPeriod (factoryServer, period) {
  return request({
    url: `${factoryServer}/api/supervisor/temperature-map`,
    method: 'get',
    withCredentials: true,
    params: {
      start: period.periodStart,
      end: period.periodEnd
    }
  })
}

export function fetchFactoryPlanMetricsInPeriod (factoryServer, period) {
  return request({
    url: `${factoryServer}/api/supervisor/production/metrics/plan`,
    method: 'get',
    withCredentials: true,
    params: {
      start: period.periodStart,
      end: period.periodEnd
    }
  })
}

export function fetchFactoryEstimationMetricsInPeriod (factoryServer, period) {
  return request({
    url: `${factoryServer}/api/supervisor/production/metrics/estimation`,
    method: 'get',
    withCredentials: true,
    params: {
      start: period.periodStart,
      end: period.periodEnd
    }
  })
}

export function fetchFactoryFactMetricsInPeriod (factoryServer, period) {
  return request({
    url: `${factoryServer}/api/supervisor/production/metrics/fact`,
    method: 'get',
    withCredentials: true,
    params: {
      start: period.periodStart,
      end: period.periodEnd
    }
  })
}
