import Vue from 'vue'
import { groupBy } from 'lodash'
import {
  fetchFishSalesBetween,
  fetchMomentMetricsByTanks,
  fetchMomentMetricsByTank
} from '@/modules/core/api/indicators'
import { havePeriodsHitDate } from '@/utils/date'
import {
  makeTankMetrics,
  makeSiteMetrics
} from '@/modules/core/use/indicators'
import {
  getSalesPeriod,
  getBriefDeltaDate,
  getWaterIndicators
} from '@/modules/core/use/preferences'

export default {
  namespaced: true,

  state: {
    isLoadingMetrics: false,
    prevByTanks: {},
    prevSalesByTanks: {},
    byTanks: {},
    salesByTanks: {}
  },

  getters: {
    tankMetricNames: (state, getters, rootState) => {
      return [
        'fishAppearanceDate',
        'fishGrowingCycle',
        'fishChange',
        'feeding',
        'notEmptyFeeding',
        'mortality',
        'seeding',
        'catch',
        'moving',
        'fishWeight',
        'sale',
        ...getWaterIndicators(rootState.preferences.all)
      ]
    },

    byTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        acc[tank.id] = makeTankMetrics(state.byTanks[tank.id])
        return acc
      }, {})
    },
    hasFishByTanks: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/tanks/forUser'].reduce((acc, tank) => {
        const f = getters.byTanks[tank.id]?.fishChange
        acc[tank.id] = f && f.fishAmount > 0 && f.fishBiomass > 0
        return acc
      }, {})
    },

    bySites: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/sites/forUser'].reduce((acc, site) => {
        const saleRecords = site.tankIds
          .flatMap(tankId => state.salesByTanks[tankId] || [])
        const prevSaleRecords = site.tankIds
          .flatMap(tankId => state.prevSalesByTanks[tankId] || [])
        acc[site.id] = makeSiteMetrics({
          tankIds: site.tankIds,
          prevMetricsByTanks: state.prevByTanks,
          prevSaleRecords,
          saleRecords,
          metricsByTanks: getters.byTanks,
          hasFishByTanks: getters.hasFishByTanks
        })
        return acc
      }, {})
    },
    hasFishBySites: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/sites/forUser'].reduce((acc, site) => {
        acc[site.id] = getters.bySites[site.id]?.fishAmount > 0
        return acc
      }, {})
    },

    byVirtualSites: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/virtualSites/forUser'].reduce((acc, site) => {
        const saleRecords = site.tankIds.flatMap(tankId => {
          const records = state.salesByTanks[tankId] || []
          const periods = site.activityMap[tankId] || []
          return periods.length
            ? records.filter(v => havePeriodsHitDate(periods, v.timestamp))
            : records
        })
        const prevSaleRecords = site.tankIds.flatMap(tankId => {
          const records = state.prevSalesByTanks[tankId] || []
          const periods = site.activityMap[tankId] || []
          return periods.length
            ? records.filter(v => havePeriodsHitDate(periods, v.timestamp))
            : records
        })
        acc[site.id] = makeSiteMetrics({
          tankIds: site.curTankIds,
          prevMetricsByTanks: state.prevByTanks,
          prevSaleRecords,
          saleRecords,
          metricsByTanks: getters.byTanks,
          hasFishByTanks: getters.hasFishByTanks
        })
        return acc
      }, {})
    },
    hasFishByVirtualSites: (state, getters, rootState, rootGetters) => {
      return rootGetters['factory/virtualSites/forUser'].reduce((acc, site) => {
        acc[site.id] = getters.byVirtualSites[site.id]?.fishAmount > 0
        return acc
      }, {})
    }
  },

  mutations: {
    SET_LOADING_METRICS: (state, isLoading) => {
      state.isLoading = isLoading
    },

    SET_BY_TANKS: (state, data) => {
      state.byTanks = Object.keys(data).reduce((acc, tankId) => {
        acc[tankId] = Object.freeze(data[tankId])
        return acc
      }, {})
    },
    SET_BY_TANK: (state, { tankId, data }) => {
      Vue.set(state.byTanks, tankId, Object.freeze(data))
    },

    SET_PREV_BY_TANKS: (state, data) => {
      state.prevByTanks = data
    },
    SET_PREV_BY_TANK: (state, { tankId, data }) => {
      Vue.set(state.prevByTanks, tankId, data)
    },

    SET_SALES_BY_TANKS: (state, data) => {
      state.salesByTanks = data
    },
    SET_SALES_BY_TANK: (state, { tankId, data }) => {
      Vue.set(state.salesByTanks, tankId, data)
    },

    SET_PREV_SALES_BY_TANKS: (state, data) => {
      state.prevSalesByTanks = data
    },
    SET_PREV_SALES_BY_TANK: (state, { tankId, data }) => {
      Vue.set(state.prevSalesByTanks, tankId, data)
    }
  },

  actions: {
    async fetchByTanks ({ commit, getters }) {
      commit('SET_LOADING_METRICS', true)
      const timestamp = new Date().toISOString()
      try {
        const response = await fetchMomentMetricsByTanks(
          timestamp,
          getters.tankMetricNames
        )
        commit('SET_BY_TANKS', response.data)
        return response.data
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING_METRICS', false)
      }
    },
    async fetchByTank ({ commit, getters }, tankId) {
      commit('SET_LOADING_METRICS', true)
      const timestamp = new Date().toISOString()
      try {
        const response = await fetchMomentMetricsByTank(
          tankId,
          timestamp,
          getters.tankMetricNames
        )
        commit('SET_BY_TANK', {
          tankId,
          data: response.data
        })
        return response.data
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING_METRICS', false)
      }
    },

    async fetchPrevByTanks ({ commit, rootState }) {
      commit('SET_LOADING_METRICS', true)
      const timestamp = getBriefDeltaDate(
        rootState.preferences.all,
        new Date()
      ).toISOString()
      try {
        const response = await fetchMomentMetricsByTanks(timestamp, [
          'fishChange'
        ])
        commit('SET_PREV_BY_TANKS', response.data)
        return response.data
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING_METRICS', false)
      }
    },
    async fetchPrevByTank ({ commit, rootState }, tankId) {
      commit('SET_LOADING_METRICS', true)
      const timestamp = getBriefDeltaDate(
        rootState.preferences.all,
        new Date()
      ).toISOString()
      try {
        const response = await fetchMomentMetricsByTank(tankId, timestamp, [
          'fishChange'
        ])
        commit('SET_PREV_BY_TANK', {
          tankId,
          data: response.data
        })
        return response.data
      } catch (e) {
        return Promise.reject(e)
      } finally {
        commit('SET_LOADING_METRICS', false)
      }
    },

    async fetchSalesByTanks ({ commit, rootState }) {
      try {
        const period = getSalesPeriod(rootState.preferences.all)
        const { data } = await fetchFishSalesBetween(...period)
        commit('SET_SALES_BY_TANKS', groupBy(data, 'tankId'))
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async fetchSalesByTank ({ commit, rootState }, tankId) {
      try {
        const period = getSalesPeriod(rootState.preferences.all)
        const { data } = await fetchFishSalesBetween(...period, tankId)
        commit('SET_SALES_BY_TANK', { tankId, data })
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async fetchPrevSalesByTanks ({ commit, rootState }) {
      try {
        const period = getSalesPeriod(rootState.preferences.all)
        period[1] = getBriefDeltaDate(
          rootState.preferences.all,
          new Date()
        ).toISOString()
        const { data } = await fetchFishSalesBetween(...period)
        commit('SET_PREV_SALES_BY_TANKS', groupBy(data, 'tankId'))
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async fetchPrevSalesByTank ({ commit, rootState }, tankId) {
      try {
        const period = getSalesPeriod(rootState.preferences.all)
        period[1] = getBriefDeltaDate(
          rootState.preferences.all,
          new Date()
        ).toISOString()
        const { data } = await fetchFishSalesBetween(...period, tankId)
        commit('SET_PREV_SALES_BY_TANK', { tankId, data })
        return data
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }
}
