import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  add
} from '@/utils/date'
import { i18n } from '@/i18next'

export const PERIOD = {
  today: () => {
    const today = new Date()
    return [startOfDay(today), endOfDay(today)]
  },
  yesterday: () => {
    const yesterday = add(new Date(), { days: -1 })
    return [startOfDay(yesterday), endOfDay(yesterday)]
  },
  week: () => {
    const today = new Date()
    return [startOfWeek(today), endOfWeek(today)]
  },
  pastWeek: () => {
    const today = new Date()
    const pastWeekDate = add(today, { weeks: -1, days: 1 })
    return [startOfDay(pastWeekDate), endOfDay(today)]
  },
  prevWeek: () => {
    const today = new Date()
    return [
      add(startOfWeek(today), { weeks: -1 }),
      add(endOfWeek(today), { weeks: -1 })
    ]
  },
  month: () => {
    const today = new Date()
    return [startOfMonth(today), endOfMonth(today)]
  },
  pastMonth: () => {
    const today = new Date()
    const pastMonthDate = add(today, { months: -1, days: 1 })
    return [startOfDay(pastMonthDate), endOfDay(today)]
  },
  prevMonth: () => {
    const prevMonthDate = new Date()
    prevMonthDate.setDate(0)
    return [startOfMonth(prevMonthDate), endOfMonth(prevMonthDate)]
  }
}

export const TODAY_SHORTCUT = {
  text: i18n.t('core.period.today'),
  onClick (picker) {
    picker.$emit('pick', PERIOD.today())
  }
}

export const YESTERDAY_SHORTCUT = {
  text: i18n.t('core.period.yesterday'),
  onClick (picker) {
    picker.$emit('pick', PERIOD.yesterday())
  }
}

export const CURRENT_WEEK_SHORTCUT = {
  text: i18n.t('core.period.week'),
  onClick (picker) {
    picker.$emit('pick', PERIOD.week())
  }
}

export const PAST_WEEK_SHORTCUT = {
  text: i18n.t('core.period.pastWeek'),
  onClick (picker) {
    picker.$emit('pick', PERIOD.pastWeek())
  }
}

export const PREV_WEEK_SHORTCUT = {
  text: i18n.t('core.period.prevWeek'),
  onClick (picker) {
    picker.$emit('pick', PERIOD.prevWeek())
  }
}

export const CURRENT_MONTH_SHORTCUT = {
  text: i18n.t('core.period.month'),
  onClick (picker) {
    picker.$emit('pick', PERIOD.month())
  }
}

export const PAST_MONTH_SHORTCUT = {
  text: i18n.t('core.period.pastMonth'),
  onClick (picker) {
    picker.$emit('pick', PERIOD.pastMonth())
  }
}

export const PREV_MONTH_SHORTCUT = {
  text: i18n.t('core.period.prevMonth'),
  onClick (picker) {
    picker.$emit('pick', PERIOD.prevMonth())
  }
}

export const shortcuts = [
  TODAY_SHORTCUT,
  YESTERDAY_SHORTCUT,
  CURRENT_WEEK_SHORTCUT,
  PAST_WEEK_SHORTCUT,
  PREV_WEEK_SHORTCUT,
  CURRENT_MONTH_SHORTCUT,
  PAST_MONTH_SHORTCUT,
  PREV_MONTH_SHORTCUT
]
