import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'
import { groupBy } from 'lodash'
import * as api from '@/modules/core/api/indicators'
import * as apiTanksFishChange from '@/modules/core/api/fishChanges'

export default {
  namespaced: true,
  state: {
    editingIndicator: null,
    degreeday: {},
    fishAmount: {},
    fishWeight: {},
    fishHistoryChanging: {},
    fishAmountHistory: {},
    fishBiomassHistory: {},
    seeding: {},
    mortality: {},
    mortalitySummary: [],
    catchSummary: []
  },
  getters: {
    getField,
    getTankFishHistoryChanging: state => tankId => {
      return state.fishHistoryChanging[tankId] || []
    },
    getTankFishAmountHistory: state => tankId => {
      return state.fishAmountHistory[tankId] || []
    },
    getTankFishBiomassHistory: state => tankId => {
      return state.fishBiomassHistory[tankId] || []
    },
    getTankFishAmount: state => tankId => {
      return state.fishAmount[tankId] || []
    },
    getTankFishWeight: state => tankId => {
      return state.fishWeight[tankId] || []
    }
  },
  mutations: {
    updateField,

    SET_FISH_CHANGE_HISTORY: (state, fishChangeHistory) => {
      Vue.set(state, 'fishHistoryChanging', fishChangeHistory)
    },
    SET_DEGREEDAY: (state, degreeday) => {
      Vue.set(state, 'degreeday', degreeday)
    },
    SET_TANK_FISH_HISTORY_CHANGING: (state, { tankId, fishHistoryChanging }) => {
      Vue.set(state.fishHistoryChanging, tankId, fishHistoryChanging)
    },
    SET_TANK_FISH_AMOUNT_HISTORY: (state, { tankId, fishAmountHistory }) => {
      Vue.set(state.fishAmountHistory, tankId, fishAmountHistory)
    },
    SET_TANK_FISH_BIOMASS_HISTORY: (state, { tankId, fishBiomassHistory }) => {
      Vue.set(state.fishBiomassHistory, tankId, fishBiomassHistory)
    },
    SET_TANK_FISH_AMOUNT: (state, { tankId, fishAmount }) => {
      Vue.set(state.fishAmount, tankId, fishAmount)
    },
    SET_TANK_FISH_WEIGHT: (state, { tankId, fishWeight }) => {
      Vue.set(state.fishWeight, tankId, fishWeight)
    },
    SET_TANK_FISH_STOCKING: (state, { tankId, seeding }) => {
      Vue.set(state.seeding, tankId, seeding)
    },
    SET_TANK_FISH_MORTALITY: (state, { tankId, mortality }) => {
      Vue.set(state.mortality, tankId, mortality)
    },
    SET_MORTALITY_SUMMARY: (state, summary) => {
      Vue.set(state, 'mortalitySummary', summary)
    },
    SET_CATCH_SUMMARY: (state, summary) => {
      Vue.set(state, 'catchSummary', summary)
    },
    SET_EDITING_INDICATOR: (state, indicator) => {
      state.editingIndicator = indicator
    }
  },
  actions: {
    openIndicatorEditor ({ commit }, indicator) {
      commit('SET_EDITING_INDICATOR', indicator)
      commit('wizard/OPEN_WIZARD', 'editIndicator', { root: true })
    },
    async saveIndicator ({ commit }, indicator) {
      try {
        await api.saveIndicators([indicator])
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async saveIndicators ({ commit }, indicators) {
      try {
        await api.saveIndicators(indicators)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async updateIndicator ({ commit }, payload) {
      try {
        await api.updateIndicator(payload)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchFishChangeHistoryInPeriod ({ commit }, {
      periodStart, periodEnd
    }) {
      try {
        var response = await apiTanksFishChange
          .fetchFishChangeHistoryInPeriod({ periodStart, periodEnd })
      } catch (error) {
        return Promise.reject(error)
      }
      const indicatorsByTanks = groupBy(response.data, i => i.tankId)
      commit('SET_FISH_CHANGE_HISTORY', indicatorsByTanks)
    },
    async fetchTankFishChangeHistoryInPeriod ({ commit }, {
      tankId, periodStart, periodEnd
    }) {
      let responses
      try {
        responses = await apiTanksFishChange.fetchTankFishHistoryChangingInPeriod({
          tankId, periodStart, periodEnd
        })
      } catch (error) {
        return Promise.reject(error)
      }
      const fishHistoryChanging = responses.data
      commit('SET_TANK_FISH_HISTORY_CHANGING', { tankId, fishHistoryChanging })
      return fishHistoryChanging
    },
    async fetchTankFishInPeriod ({ commit }, {
      tankId, periodStart, periodEnd
    }) {
      let responses
      try {
        responses = await Promise.all([
          api.fetchTankFishAmountInPeriod({ tankId, periodStart, periodEnd }),
          api.fetchTankFishWeightInPeriod({ tankId, periodStart, periodEnd })
        ])
      } catch (error) {
        return Promise.reject(error)
      }
      const fishAmount = responses[0].data
      const fishWeight = responses[1].data
      commit('SET_TANK_FISH_AMOUNT', { tankId, fishAmount })
      commit('SET_TANK_FISH_WEIGHT', { tankId, fishWeight })
      return { fishAmount, fishWeight }
    },
    async fetchTanksDegreedayInPeriod ({ commit }, { periodStart, periodEnd }) {
      try {
        var response = await api.fetchTanksDegreedayInPeriod({ periodStart, periodEnd })
      } catch (error) {
        return Promise.reject(error)
      }
      commit('SET_DEGREEDAY', response.data)
    },
    async fetchTankFishSeedingInPeriod ({ commit }, {
      tankId, periodStart, periodEnd
    }) {
      try {
        const response = await api.fetchTankFishSeedingInPeriod({ tankId, periodStart, periodEnd })
        const seeding = response.data
        commit('SET_TANK_FISH_STOCKING', { tankId, seeding })
        return seeding
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchTankMortalityInPeriod ({ commit }, {
      tankId, periodStart, periodEnd
    }) {
      try {
        const response = await api.fetchTankMortalityInPeriod({ tankId, periodStart, periodEnd })
        const mortality = response.data
        commit('SET_TANK_FISH_MORTALITY', { tankId, mortality })
        return mortality
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchMortalitySummaryInPeriod ({ commit }, params) {
      try {
        const response = await api.fetchMortalitySummaryInPeriod(params)
        const data = response.data
        commit('SET_MORTALITY_SUMMARY', data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async deleteIndicator ({ commit }, id) {
      try {
        await api.deleteIndicator(id)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async fetchCatchSummaryInPeriod ({ commit }, params) {
      try {
        const response = await api.fetchCatchSummaryInPeriod(params)
        const data = response.data
        commit('SET_CATCH_SUMMARY', data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
}
