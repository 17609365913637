import Vue from 'vue'
import * as api from '@/modules/supervisor/api/degreeday'

export default {
  namespaced: true,
  state: {
    degreeday: []
  },
  getters: {
    getBySiteIdAndYear: state => (siteId, year) => {
      const v = state.degreeday.find(v => v.siteId === siteId && v.year === year)
      return v?.degreeday ?? null
    }
  },
  mutations: {
    FETCH_DEGREEDAY: (state, degreeday) => {
      const makeItemKey = item => `${item.siteId}-${item.year}`
      const itemKeys = degreeday.map(makeItemKey)
      const newValue = state.degreeday.filter(item => {
        return !itemKeys.includes(makeItemKey(item))
      }).concat(degreeday)
      Vue.set(state, 'degreeday', newValue)
    }
  },
  actions: {
    async fetchDegreedayInYear ({ commit }, year) {
      try {
        var response = await api.fetchDegreedayInYear(year)
      } catch (error) {
        return Promise.reject(error)
      }
      commit('FETCH_DEGREEDAY', response.data)
    },
    async fetchDegreedayInPeriod ({ commit }, period) {
      try {
        var response = await api.fetchDegreedayInPeriod(period)
      } catch (error) {
        return Promise.reject(error)
      }
      commit('FETCH_DEGREEDAY', response.data)
    }
  }
}
