import { mapFields } from 'vuex-map-fields'
import WizardTypePicker from '@/modules/core/components/WizardTypePicker'
import SubmitButton from '@/modules/core/components/WizardUtilSubmitButton'
import CloseButton from '@/modules/core/components/WizardUtilCloseButton'

export default {
  components: {
    WizardTypePicker,
    SubmitButton,
    CloseButton
  },
  computed: {
    ...mapFields('wizard', {
      isWizardVisible: 'isVisible'
    })
  },
  methods: {
    closeWizard () {
      this.isWizardVisible = false
    }
  }
}
