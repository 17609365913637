<template>
  <el-dialog
    top="0"
    :visible.sync="isVisible"
    :close-on-click-modal="false"
    :show-close="false"
    class="wizard-dialog"
    @closed="resetWizardForm"
  >
    <component :is="wizardForm" />
  </el-dialog>
</template>

<script>
import WizardLayout from '@/modules/core/components/WizardLayout'
import { mapFields } from 'vuex-map-fields'

const wizardForms = {
  editIndicator: () => import('@/modules/core/components/WizardEditIndicator'),
  productionPlan: () => import('@/modules/supervisor/components/WizardProductionPlan')
}

export default {
  name: 'Wizard',
  data () {
    return {
      wizardForm: WizardLayout
    }
  },
  computed: {
    ...mapFields('wizard', {
      isVisible: 'isVisible',
      type: 'type'
    })
  },
  watch: {
    type: 'updateWizardForm'
  },
  methods: {
    async updateWizardForm (type) {
      if (!wizardForms.hasOwnProperty(type)) {
        this.wizardForm = WizardLayout
        return
      }
      this.wizardForm = wizardForms[type]
    },
    resetWizardForm () {
      this.type = null
    }
  }
}
</script>
