<template>
  <div class="app-wrapper">
    <app-toolbar class="app-toolbar" />
    <app-navbar class="app-navbar" />
    <wizard />
    <router-view class="app-content" />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import acl from '@/acl'
import { getFactoryContentForUser } from '@/modules/core/use/factory'
import AppToolbar from '@/modules/core/components/Toolbar'
import AppNavbar from '@/modules/core/components/NavBar'
import Wizard from '@/modules/core/components/Wizard'
import { subscribe, N } from '@/plugins/io'
import { getNavbarItems } from '@/router'

export default {
  name: 'Layout',

  components: {
    AppToolbar,
    AppNavbar,
    Wizard
  },
  computed: {
    periodStart () {
      return this.$store.getters['core/app/periodStart']
    },
    periodEnd () {
      return this.$store.getters['core/app/periodEnd']
    }
  },
  created () {
    this.initUserFactoryDebounced = debounce(this.initUserFactory, 500)
    this.onTouchAclResourcesDebounced = debounce(this.onTouchAclResources, 500)
    this._subscriptions = this.makeSubscriptions()
  },
  beforeDestroy () {
    this.initUserFactoryDebounced.cancel()
    this.onTouchAclResourcesDebounced.cancel()
    if (this._subscriptions) {
      for (const unsubscribe of this._subscriptions) {
        unsubscribe()
      }
    }
  },
  methods: {
    async initUserFactory (user) {
      await acl.initByUser(user)
      this.updateNavbarItems()
      await this.$store.dispatch('factory/fetchFactoryContent')
    },
    updateNavbarItems () {
      this.$store.commit('core/app/SET_NAVBAR_ITEMS', getNavbarItems())
    },
    makeSubscriptions () {
      return [
        subscribe(N.TOUCH_TANKS_METRICS, this.onTouchTanksMetrics),
        subscribe(N.TOUCH_SUBJECT_ACL, this.onTouchSubjectAcl),
        subscribe(N.TOUCH_ACL_RESOURCES, this.onTouchAclResourcesDebounced)
      ]
    },
    async onTouchSubjectAcl (event) {
      const user = this.$store.state.auth.me
      if (user && user.id === event.subjectId) {
        this.initUserFactoryDebounced(user)
      }
    },
    async onTouchAclResources () {
      const user = this.$store.state.auth.me
      await this.$store.dispatch('factory/fetchFactoryContent')
      if (user) {
        await acl.initByUser(user)
        this.updateNavbarItems()
        this.$store.commit(
          'factory/SET_FACTORY_CONTENT',
          getFactoryContentForUser(this.$store.state.factory.allContent, acl)
        )
      }
    },
    onTouchTanksMetrics (event) {
      event.tankIds.forEach(tankId => {
        this.fetchTankMetrics(tankId)
        this.fetchTankSales(tankId)
      })
    },
    fetchTankMetrics (tankId) {
      this.$store.dispatch('factory/metrics/fetchByTank', tankId)
    },
    fetchTankSales (tankId) {
      this.$store.dispatch('factory/metrics/fetchSalesByTank', tankId)
    }
  }
}
</script>

<style lang="scss">
.app-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: var(--navbar-width) minmax(0, 1fr);
  grid-template-rows: var(--toolbar-height) minmax(0, 1fr);
  grid-template-areas:
    "toolbar toolbar"
    "navbar content";
  min-width: 1280px;
  transition: 0.3s;

  p {
    font-size: 16px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
  }

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
}

.app-toolbar {
  grid-area: toolbar;
}
.app-navbar {
  grid-area: navbar;
}
.app-content {
  grid-area: content;
}

.highlight {
  &.info-highlight {
    background-color:  var(--color-info-transparent-300);
  }
  &.success-highlight {
    background-color:  var(--color-success-transparent-300);
  }
  &.warning-highlight {
    background-color:  var(--color-warning-transparent-300);
  }
  &.danger-highlight {
    background-color:  var(--color-danger-transparent-300);
  }
}

.table-button {
  padding: 5px !important;
  i {
    font-size: 18px !important;
  }
  span {
    font-size: 16px !important;
  }
}
.table-button.el-button.el-button--small {
  i {
    font-size: 16px !important;
  }
}
.table-button.el-button.el-button--mini {
  i {
    font-size: 14px !important;
  }
}

// fixes
.el-date-table, .el-month-table, .el-year-table {
  font-size: unset !important;
}

.period-picker-with-shortcuts {
  --shortcuts-width: 150px;
  .el-picker-panel__sidebar {
    width: var(--shortcuts-width) !important;
  }
  .el-picker-panel__body {
    margin-left: var(--shortcuts-width) !important;
  }
}
</style>
