import Vue from 'vue'
import { PERIOD } from '@/modules/core/use/datePicker'
import * as apiProduction from '@/modules/supervisor/api/production'

const makeMonthPeriod = () => {
  const period = PERIOD.month()
  return {
    periodStart: period[0].toISOString(),
    periodEnd: period[1].toISOString()
  }
}

export default {
  namespaced: true,
  state: {
    period: makeMonthPeriod(),
    loadingItems: [],
    factMetricsByTanks: {},
    estimationMetricsByTanks: {},
    allPlanMetricsByTanks: {},
    planMetricsByTanks: {}
  },
  getters: {
    allPlanMetricsByTank: state => tankId => {
      return state.allPlanMetricsByTanks[tankId] || {}
    }
  },
  mutations: {
    CHANGE_PERIOD: (state, period) => {
      let value = period
      if (Array.isArray(period)) {
        value = {
          periodStart: period[0],
          periodEnd: period[1]
        }
      }
      Vue.set(state, 'period', value)
    },
    RESET_PERIOD: (state, period) => {
      Vue.set(state, 'period', makeMonthPeriod())
    },
    ADD_LOADING_ITEM: (state, item) => {
      state.loadingItems.push(item)
    },
    REMOVE_LOADING_ITEM: (state, item) => {
      const idx = state.loadingItems.findIndex(val => val === item)
      if (idx !== -1) {
        state.loadingItems.splice(idx, 1)
      }
    },
    RESET_METRICS: state => {
      Vue.set(state, 'factMetricsByTanks', {})
      Vue.set(state, 'estimationMetricsByTanks', {})
      Vue.set(state, 'planMetricsByTanks', {})
    },
    SET_FACT_METRICS: (state, metrics) => {
      Vue.set(state, 'factMetricsByTanks', Object.freeze(metrics))
    },
    SET_ESTIMATION_METRICS: (state, metrics) => {
      Vue.set(state, 'estimationMetricsByTanks', Object.freeze(metrics))
    },
    SET_ALL_PLAN_METRICS: (state, metrics) => {
      Vue.set(state, 'allPlanMetricsByTanks', Object.freeze(metrics))
    },
    SET_PLAN_METRICS_IN_PERIOD: (state, metrics) => {
      Vue.set(state, 'planMetricsByTanks', Object.freeze(metrics))
    }
  },
  actions: {
    async fetchPlanMetricsInPeriod ({ commit }, { periodStart, periodEnd }) {
      try {
        console.time('fetchPlanMetrics')
        commit('ADD_LOADING_ITEM', 'fetchPlanMetrics')
        const response = await apiProduction.fetchPlanMetricsInPeriod({
          periodStart,
          periodEnd
        })
        const planMetricsInPeriod = response.data
        const tankIds = Object.keys(planMetricsInPeriod)
        const allPlanMetrics = tankIds.reduce((acc, tankId) => {
          acc[tankId] = planMetricsInPeriod[tankId].reduce((acc, item) => {
            if (item.planId) {
              acc[item.date] = item
            }
            return acc
          }, {})
          return acc
        }, {})
        commit('SET_ALL_PLAN_METRICS', allPlanMetrics)
        commit('SET_PLAN_METRICS_IN_PERIOD', planMetricsInPeriod)
        return planMetricsInPeriod
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('REMOVE_LOADING_ITEM', 'fetchPlanMetrics')
        console.timeEnd('fetchPlanMetrics')
      }
    },
    async fetchEstimationMetricsInPeriod ({ commit }, { periodStart, periodEnd }) {
      try {
        console.time('fetchEstimationMetrics')
        commit('ADD_LOADING_ITEM', 'fetchEstimationMetrics')
        const response = await apiProduction.fetchEstimationMetricsInPeriod({
          periodStart,
          periodEnd
        })
        const estimationMetricsInPeriod = response.data
        commit('SET_ESTIMATION_METRICS', estimationMetricsInPeriod)
        return estimationMetricsInPeriod
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('REMOVE_LOADING_ITEM', 'fetchEstimationMetrics')
        console.timeEnd('fetchEstimationMetrics')
      }
    },
    async fetchFactMetricsInPeriod ({ commit }, { periodStart, periodEnd }) {
      try {
        console.time('fetchFactMetrics')
        commit('ADD_LOADING_ITEM', 'fetchFactMetrics')
        const response = await apiProduction.fetchFactMetricsInPeriod({
          periodStart,
          periodEnd
        })
        const factMetricsInPeriod = response.data
        commit('SET_FACT_METRICS', factMetricsInPeriod)
        return factMetricsInPeriod
      } catch (e) {
        console.log(e)
        throw e
      } finally {
        commit('REMOVE_LOADING_ITEM', 'fetchFactMetrics')
        console.timeEnd('fetchFactMetrics')
      }
    }
  }
}
